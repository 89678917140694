import React, {useEffect, useState, useContext} from 'react';
import {
    Box,
    Center,
    Text,
    VStack,
    RadioGroup,
    Radio,
    Collapse,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Input,
    FormControl,
    Button,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    FormLabel,
    useToast,
    HStack,
    useDisclosure,
    Wrap, WrapItem, InputRightElement, InputGroup, IconButton,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    ButtonGroup, Portal, Grid, Heading, GridItem, Flex, Spacer, Stack,
} from "@chakra-ui/react"
import { InfoIcon, EditIcon } from '@chakra-ui/icons'
import {useSessionContext} from "supertokens-auth-react/recipe/session";
import DisplayChannelText from "./DisplayChannelText";
import Instructions from "./Instructions";
import {UserContext} from "../UserContext";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Logtail } from "@logtail/browser";

function SettingStack(props){
    const user = useContext(UserContext);
    let user_details = user.user_details
    let channels = props.channels
    let user_channel_details = user.user_channel_details
    // let [output, setOutput] = useState([])
    let [emailIsChannel, setEmailIsChannel] = useState(false)
    let [phoneIsChannel, setPhoneIsChannel] = useState(false)
    let [editMode, setEditMode] = useState(false)
    let [formState, setFormState] = useState({});
    let setNeedsRefresh = user.setNeedsRefresh
    let session = useSessionContext();
    let {doesSessionExist, userId, accessTokenPayload} = session;
    const [defaultChannel, setDefaultChannel] = useState('email')
    // const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const toast = useToast()
    // const navigate = useNavigate()
    // const {isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    let [instructionsNeeded, setInstructionsNeeded] = useState(null)
    let gridRows = channels.length + 3 // 1 header + 1 email + 1 text message + apprise
    let gridRowsString = 'repeat(' + gridRows + ', 1fr)'
    // const { isOpen, onToggle } = useDisclosure()
    // console.log(gridRowsString)
    // let string = 'repeat(2, 1fr)'
    const { isOpen, onToggle } = useDisclosure()
    const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN)

    // GRID SETTINGS
    let radioGridWitdh = '4.5em'
    let radioGridHeight = '25px'

    useEffect(() => {
    //     console.log(Object.keys(user_channel_details).length)
    //     console.log(Object.keys(user_channel_details))
        Object.keys(user_channel_details).forEach(function(key) {
            // console.log(key, user_channel_details[key]);
            if (user_channel_details[key]["default"] === true){
                setDefaultChannel(key)
                // console.log(key + ' is the default channel')
            }
        });
    }, [user_channel_details])


    useEffect(() => {

        // Is this necessary?
        if (Object.keys(user_channel_details).includes('email')){
            // console.log('email is a key')
            setEmailIsChannel(true)
        }
        if (Object.keys(user_channel_details).includes('phone')){
            // console.log('phone is a key')
            setPhoneIsChannel(true)
        }
    }, [user_channel_details])


    function handleFormChange(e) {
        setFormState(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    let handleDefaultChange = async (e) => {
        setDefaultChannel(e)
        // console.log('default channel is now ' + e)
    //     console.log("superTokensId: " + userId)
    //     console.log("channelId: " + user_channel_details[e].channel_id)
        let obj = {}
        obj.supertokens_id = userId
        obj.channel_id = user_channel_details[e].channel_id
        try{
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/set-default-channel", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
            if (res.status ===200) {
                // console.log("default channel updated successfully");
                // console.log(e)
                toast({
                    title: e + " is now your default channel",
                    status: "success",
                    isClosable: true
                })
            } else {
                console.log("some error occurred");
                logtail.error(user_details.email + ": some error occurred while setting default channel")
                logtail.flush()
                toast({
                    title: "some error occurred",
                    status: "error",
                    isClosable: true
                })
            }
        } catch (err) {
            logtail.error(user_details.email + ": some error occurred while setting default channel", err)
            logtail.flush()
            console.log(err);
        }

        setNeedsRefresh(true)
    }

    let handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(e)
        let obj = {}
        obj.params = formState
        obj.service_name = e.target.id
        obj.userId = userId
        // console.log(obj)
        try{
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/add-channel", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
            let resJson = await res.json();
            if (res.status === 200) {
                // console.log("channel created successfully");
                toast({
                    title: e.target.id + " created successfully",
                    status: "success",
                    isClosable: true
                })
                setEditMode(null)
                setNeedsRefresh(true)
            } else {
                console.log("some error occurred");
                logtail.error(user_details.email + ": some error occurred while adding a channel")
                logtail.flush()
                toast({
                    title: "Error in 'else' creating " + e.target.id,
                    status: "error",
                    isClosable: true
                })
            }
        } catch (err) {
            console.log(err);
            logtail.error(user_details.email + ": some error occurred while adding a channel", err)
            logtail.flush()
            toast({
                title: "Error in 'catch' creating " + e.target.id,
                status: "error",
                isClosable: true
            })
        }
        // props.setUserIsLoaded(false)
        setEditMode(null)
        setNeedsRefresh(true)
        // console.log("just ran setUserIsLoaded(false) within handlesubmit")
    //     https://www.techomoro.com/submit-a-form-data-to-rest-api-in-a-react-app/
    //     console.log(formState)
    //     console.log(dumb)
    }

    function deleteChannel(service_name){
        // console.log("in deletechannel")
        // console.log(props)
        // console.log("service is " + service_name)
        // console.log("delete channel ID: " + props.user_channel_details[service_name].channel_id)
        // console.log(formState)
        try{
            const url = process.env.REACT_APP_API_DOMAIN + "/delete-channel";
            const options = {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "supertokens_id": props.user_details.supertokens_id,
                    "channel_id": props.user_channel_details[service_name].channel_id,
                }),
            };
            fetch(url, options)
                .then(response => response.json())
                .then(data => console.log(data))
            setNeedsRefresh(true)
            setFormState({})
        } catch (err) {
            console.log(err)
            logtail.error(user_details.email + ": some error occurred while deleting a channel", err)
            logtail.flush()
        }

        setNeedsRefresh(true)
    }

    function editChannel(e) {
        // console.log(e.target.id)
        setEditMode(e.target.id)

    }

    function handleInstructions(e) {
        // console.log(e)
        if (instructionsNeeded === null || instructionsNeeded !== e.target.id) {
            setInstructionsNeeded(e.target.id)
        } else {
            setInstructionsNeeded(null)
        }
        // setInstructionsNeeded(e.target.id)
        // setInstructions(e.target.id)
    }


    return (
        <Box pr={2} pl={2} w={'100%'}>
            {/*onChange={handleDefaultChange}*/}
            <RadioGroup onChange={handleDefaultChange} value={defaultChannel}>
                <Grid
                    // templateRows={gridRowsString}
                    // templateColumns='repeat(2, 1fr)'
                    gridTemplateColumns={'5em 1fr'}
                      // h={'200px'}
                >
                    {/*<GridItem rowSpan={1} colSpan={2} bg={'red'} w={'100%'} h={'4px'}/>*/}
                    <GridItem rowSpan={1} colSpan={1} p={2}>
                        <Heading fontSize={'sm'} color={'gray.600'}>DEFAULT</Heading>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={1} p={2}>
                        <Heading fontSize={'sm'} color={'gray.600'}>CHANNEL</Heading>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={2} bg={'gray.300'} h={'1px'} />

                    <StaticSetting id='email' title='Email' body={user_details["email"]} isDiabled={false} radioGridWidth={radioGridWitdh} radioGridHeight={radioGridHeight} />
                    <StaticSetting id='app' title='iPhone & Android Apps' body='Coming Soon!' isDisabled={true} radioGridWidth={radioGridWitdh} radioGridHeight={radioGridHeight} />
                    <StaticSetting id='text' title='Text Message' body='Coming Soon!' isDisabled={true} radioGridWidth={radioGridWitdh} radioGridHeight={radioGridHeight} />


                {/*    Time to start dynamically generating*/}

                    {channels.map(function(channel){
                        if (user_channel_details[channel.service_name] !== undefined && channel.service_name !== editMode){

                            return(
                                <>
                                    <ChannelIsEstablished channel={channel} deleteChannel={deleteChannel} editChannel={editChannel} service_name={channel.service_name} radioGridWidth={radioGridWitdh} radioGridHeight={radioGridHeight}/>
                                </>
                            )
                        }
                        else {
                            let tempArray = [];
                            Object.keys(channel.details.tokens).map(function(key){
                                // console.log(key)
                                // tempArray.push(key);
                                if (key === "schema") {
                                    // console.log("schema found")
                                    // for now we aren't giving 'schema' to the user
                                } else {
                                    tempArray.push(key);
                                }
                            })
                            let buildArray = [];
                            for (let i = 0; i < tempArray.length; i++){ // Object.keys(channel_details.details.tokens).length
                                buildArray.push(<DisplayTokenAsInput key={channel.service_name+'_'+i} service_name={channel.service_name} default_values={user_channel_details} name={tempArray[i]} tokens={channel.details.tokens[tempArray[i]]} formState={formState} formChangeFunction={handleFormChange}/>)
                            }
                            // console.log(user_channel_details)
                            let buttonRow = [];
                            if (editMode === channel.service_name) {
                                buttonRow.push(<HStack><Button type={'submit'}>Submit Edit</Button><Button onClick={() => setEditMode(null)}>Cancel Edit</Button></HStack>)
                                // buttonRow.push()
                            } else {
                                buttonRow.push(<Button bg={'brand.pink'} type={'submit'}>Submit</Button>)

                            }
                            return(
                                <>
                                    <ChannelIsNotEstablished editMode={editMode} buttonRow={buttonRow} buildArray={buildArray} handleSubmit={handleSubmit} channels={channels} setInstructionsNeeded={setInstructionsNeeded} instructionsNeeded={instructionsNeeded} handleInstructions={handleInstructions} channel={channel} radioGridWidth={radioGridWitdh} radioGridHeight={radioGridHeight} />
                                </>
                            )
                        }
                    })}




                </Grid>
            </RadioGroup>

        </Box>
  )
}

function DisplayTokenAsInput(props){
    const tokens = props.tokens;
    const name = props.name;
    let formState = props.formState
    const default_values = props.default_values;
    const service_name = props.service_name;
    const [default_value_json, setDefaultValueJson] = useState({})
    const [type, setType] = useState("text");
    const [defaultValueNeeded, setDefaultValueNeeded] = useState(false);
    // console.log(default_values[service_name]["params"])
    // let default_value_json = JSON.parse((default_values[service_name]).replace(/'/g, '"')) // works for other JSON

    useEffect(() => {
        if (default_values[service_name] !== undefined) {
            setDefaultValueJson(JSON.parse((default_values[service_name]["params"]).replace(/'/g, '"')))
        }
    }, [default_values, service_name])

    useEffect(() => {
        // console.log(tokens)
        if(tokens.type === "string") {
            setType("text");
        }
        else if(tokens.type === "int"){
            setType("number");
        }
    }, [tokens]);

    // useEffect to set the default values for ReminderLoop defaults
    // Services that have a default value that we can provide:
    // Pushover: Access Token
    //
    useEffect(() => {
        // console.log(tokens)
        if (service_name === 'Pushover') {
            if (tokens.name === 'Access Token') {
                // console.log(tokens)
                setDefaultValueNeeded(true)
            }
        }
    }, [service_name, tokens])


    return (
        <Box isRequired={tokens.required} key={tokens.name}>
            <FormLabel><Text as={'span'}>{tokens.name}</Text><Text as={'span'} color={'red'}>{tokens.required ? "*": ""}</Text><Text as={'span'}>:</Text></FormLabel>

            {defaultValueNeeded ? <DefaultInputToken name={name} type={type} tokens={tokens}
                                                     formChangeFunction={props.formChangeFunction}
                                                        setDefaultValueNeeded={setDefaultValueNeeded}/> : <Input
                name = {name}
                type = {type}
                isRequired = {tokens.required}
                value = {formState[name]}
                // onChange = {(e) => setFormState( formState => ({...formState, [e.target.name]: e.target.value}))}
                onChange = {props.formChangeFunction}
            />}

        </Box>
    )
}

function StaticSetting(props) {
    const { isOpen, onToggle } = useDisclosure()
    const [textColor, setTextColor] = useState('black')
    useEffect(() => {
        if (props.isDisabled) {
            setTextColor('gray.400')
        }
    }, [props.isDisabled])

    return(
        <>
            <Center p={2}><GridItem rowSpan={1} colSpan={1} w={props.radioGridWidth} h={props.radioGridHeight}>
                <Center><Radio value={props.id} isDisabled={props.isDisabled}/></Center>
            </GridItem></Center>
            <GridItem rowSpan={1} colSpan={1} p={2}>
                <Flex alignItems={'center'}>
                    <Box>
                        <Text fontSize={'2xl'} fontWeight={'bold'} color={textColor}>{props.title}</Text>
                    </Box>
                    <Spacer />
                    <Box>
                        <IconButton bg={'brand.pink'} color={'white'} onClick={onToggle} aria-label={'expand '+props.id} icon={<KeyboardArrowDownOutlinedIcon/>} />
                    </Box>
                </Flex>
            </GridItem>
            <GridItem rowSpan={1} colSpan={2} pb={2}>
                <Collapse in={isOpen} animateOpacity>
                    <Center><Text>{props.body}</Text></Center>
                </Collapse>
            </GridItem>
            <GridItem rowSpan={1} colSpan={2} bg={'gray.300'} h={'1px'} />
        </>
    )
}

function ChannelIsEstablished(props) {
    const { isOpen, onToggle } = useDisclosure()
    return(
        <>
            <Center p={2}><GridItem rowSpan={1} colSpan={1} w={props.radioGridWidth} h={props.radioGridHeight}>
                <Center><Radio value={props.service_name} /></Center>
            </GridItem></Center>
            <GridItem rowSpan={1} colSpan={1} p={2}>
                <Flex alignItems={'center'}>
                    <Box>
                        <Text fontSize={'2xl'} fontWeight={'bold'}>{props.service_name}</Text>
                    </Box>
                    <Spacer />
                    <Box>
                        <IconButton bg={'brand.pink'} color={'white'} onClick={onToggle} aria-label={'expand '+props.id} icon={<KeyboardArrowDownOutlinedIcon/>} />
                    </Box>
                </Flex>
            </GridItem>
            <GridItem rowSpan={1} colSpan={2} pb={2}>
                <Collapse in={isOpen} animateOpacity>
                    <DisplayChannelText key={props.service_name} channel_details={props.channel} />
                    <HStack justify={'center'} pt={2}>
                        <Button color={'gray.600'} bg={'red.300'} onClick={() => props.deleteChannel(props.channel.service_name)}>Delete</Button>
                        <Button color={'gray.600'} bg={'brand.green'} id={props.service_name} onClick={props.editChannel}>Edit</Button>
                    </HStack>
                </Collapse>
            </GridItem>
            <GridItem rowSpan={1} colSpan={2} bg={'gray.300'} h={'1px'} />
        </>
    )
}

function ChannelIsNotEstablished(props) {
    const { isOpen, onToggle, onOpen} = useDisclosure()
    useEffect(() => {
        if (props.editMode === props.channel.service_name) {
            onOpen()
        }
    }, [props.editMode, props.channel.service_name])
    return(
        <>
            <Center p={2}><GridItem rowSpan={1} colSpan={1} w={props.radioGridWidth} h={props.radioGridHeight}>
                <Center><Radio value={props.channel.service_name} isDisabled/></Center>
            </GridItem></Center>
            <GridItem rowSpan={1} colSpan={1} p={2}>
                <Flex alignItems={'center'}>
                    <Box>
                        <Text fontSize={'2xl'} fontWeight={'bold'}>{props.channel.service_name}</Text>
                    </Box>
                    <Spacer />
                    <Box>
                        <IconButton bg={'brand.pink'} color={'white'} onClick={onToggle} aria-label={'expand '+props.id} icon={<KeyboardArrowDownOutlinedIcon/>} />
                    </Box>
                </Flex>
            </GridItem>
            <GridItem rowSpan={1} colSpan={2} pb={1}>
                <Collapse in={isOpen} animateOpacity>
                    <Center><Button color={'gray.600'} bg={'brand.green'} id={props.channel.service_name} onClick={props.handleInstructions} leftIcon={<InfoIcon />}>
                                    How to set up {props.channel.service_name}</Button></Center>
                    <GridItem rowSpan={1} colSpan={2}>
                        {props.instructionsNeeded!== null && <Instructions service={props.instructionsNeeded}
                                             setInstructionsNeeded={props.setInstructionsNeeded}
                                             channels={props.channels}/>}
                    </GridItem>
                    <form id={props.channel.service_name} onSubmit={props.handleSubmit}>
                        <FormControl>
                            <VStack spacing={'5px'} align={'left'}>
                                {props.buildArray}
                                {props.buttonRow}
                            </VStack>
                        </FormControl>
                    </form>
                </Collapse>
            </GridItem>
            <GridItem rowSpan={1} colSpan={2} bg={'gray.300'} h={'1px'} />
        </>
    )
}


function DefaultInputToken(props){
    const name = props.name
    const type = props.type
    const tokens = props.tokens
    const formChangeFunction = props.formChangeFunction
    const setDefaultValueNeeded = props.setDefaultValueNeeded
    // const handleChangeDefault = props.handleChangeDefault
    const { onOpen, onClose, isOpen } = useDisclosure()


    function handleChangeDefault(e) {
        // Then make it so they can edit it
        // onClose()
        setDefaultValueNeeded(false)
        console.log(e)
    }

    return(
        <InputGroup>
            <Input
                name = {name}
                type = {type}
                isRequired = {tokens.required}
                value = 'ReminderLoop Default'
                disabled
                // onChange = {(e) => setFormState( formState => ({...formState, [e.target.name]: e.target.value}))}
                onChange = {formChangeFunction}
            />
            <InputRightElement children={
                <Popover onOpen={onOpen} onClose={onClose}>
                    <PopoverTrigger>
                        <IconButton icon={<EditIcon />}/>
                    </PopoverTrigger>
                    <Portal>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader>Confirmation</PopoverHeader>
                            <PopoverBody>
                                <Text>Are you sure you want to change the ReminderLoop default?</Text>
                                <Button colorScheme='green' onClick={handleChangeDefault}>Continue</Button>
                            </PopoverBody>
                            {/*<PopoverFooter display='flex' justifyContent='flex-end'>*/}
                            {/*    <Button colorScheme='green' onClick={handleChangeDefault}>Continue</Button>*/}
                            {/*</PopoverFooter>*/}
                        </PopoverContent>
                    </Portal>
                </Popover>
            }/>
                {/*<IconButton icon={<EditIcon />} onClick={handleChangeDefault}/>}/>*/}
        </InputGroup>
    )
}


export default SettingStack;