import {createContext, useState, useEffect, } from 'react';
import {doesSessionExist, useSessionContext} from "supertokens-auth-react/recipe/session";
import {Logtail} from "@logtail/browser";

export const UserContext = createContext()


export const UserContextProvider = (props) => {
    const [user_details, setUserDetails] = useState({"reminders": "[]", "channels": "[]",
        "supertokens_id": "", "sender": {"receiver_name":"-", "sender_name":"-"},
        "receiver": {"sender_name":"-", "receiver_name":"-"}, is_active: false, is_onboarded: true
    })
    let session = useSessionContext();
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    let [needsRefresh, setNeedsRefresh] = useState(false);
    let [user_channel_details, setUserChannelDetails] = useState({"email":
            {"channel_id": 0, "default": false, "params": '{"email": "boom@boom.com"}'}})
    let [waitListAutoFocus, setWaitListAutoFocus] = useState(false)
    let [needsOnboarding, setNeedsOnboarding] = useState(false)
    let [needsToPay, setNeedsToPay] = useState(false)
    let [accountType, setAccountType] = useState("free")
    let [postLoginFreeOrPay, setPostLoginFreeOrPay] = useState(null); // 'pro' or 'free'
    let [loading, setLoading] = useState(false); // loading for grabbing data from ReminderLoop backend
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [refreshLoading, setRefreshLoading] = useState(false);

    const makeChoice = (userChoice) => {
        setPostLoginFreeOrPay(userChoice);
      };

    const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN)

    console.log(process.env.REACT_APP_MODE + " mode")

    let checkUserLoggedIn = async function () {
        let response = await doesSessionExist();
        return response
    }

    useEffect(() => {
        checkUserLoggedIn().then((result) => {
            setIsLoggedIn(result)
            // console.log("user logged in: " + isLoggedIn)
        })
    }, [isLoggedIn])
    // console.log(user_details.is_onboarded)

    // useEffect(() => {
    //     if (user_details) {
    //         const combinedReminders = [
    //             ...(user_details.received_reminders || []),
    //             ...(user_details.self_reminders || []),
    //             ...(user_details.sent_reminders || [])
    //         ];
    //         setReminders(combinedReminders);
    //     }
    // }, [user_details, user_details.received_reminders, user_details.self_reminders, user_details.sent_reminders]);


    useEffect(() => {
        async function fetchMyApi() {
            
            if (isLoggedIn) {
                setRefreshLoading(true);
                setLoading(isInitialLoad); // Only set loading to true during the initial load
                let {userId} = await session;
                const url = process.env.REACT_APP_API_DOMAIN + "/getuser"
                const options = {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        "supertokens_id": userId
                    }),
                };
                // console.log(options)
                try {
                    let res = await fetch(url, options)
                        .then((res) => res.json())
                        .then((resJson) => {
                            // console.log(resJson)
                            PostGetUserProcessing(resJson["user_details"], setUserDetails, setUserChannelDetails,
                                setNeedsOnboarding, setNeedsToPay, setAccountType)
                            setNeedsRefresh(false)
                        })

                } catch (err) {
                    console.log("error in try-catch with /getuser")
                    console.log(err)
                    logtail.error("error in try-catch with /getuser", err)
                    logtail.flush()
                    setNeedsRefresh(true)
                } finally {
                    if (isInitialLoad) {
                        setIsInitialLoad(false)
                        setLoading(false);
                    }
                    setRefreshLoading(false);
                }
            }

        }
        
        fetchMyApi();

        if (process.env.REACT_APP_MODE !== "production") {
            console.log(user_details)
        }
    }, [isLoggedIn, session, needsRefresh])
    
    return (
        <UserContext.Provider value={{user_details, setUserDetails, isLoggedIn, setIsLoggedIn, needsRefresh,
            setNeedsRefresh, user_channel_details, setUserChannelDetails, waitListAutoFocus,
            needsOnboarding, setNeedsOnboarding, setWaitListAutoFocus, needsToPay, accountType, setAccountType,
            postLoginFreeOrPay, makeChoice, loading, refreshLoading}}>
            {props.children}
        </UserContext.Provider>
    )

}

function PostGetUserProcessing(user_details, setUserDetails, setUserChannelDetails, setNeedsOnboarding, setNeedsToPay, setAccountType) {

    // setUserDetails(user_details["user_details"])
    // if (user_details.is_active === false) {
    //     setNeedsToPay(true)
    // }
    // else {
    //     setNeedsToPay(false)
    // }
    // if (user_details.is_active === true && user_details.is_onboarded === false) {
    //     setNeedsOnboarding(true)
    // }
    // else {
    //     setNeedsOnboarding(false)
    // }
    setAccountType(user_details.account_type)
    if (process.env.REACT_APP_MODE !== "production") {
        console.log("account type is " + user_details.account_type)
    }
    
    // TODO: THIS WORKS TO MAKE .REMINDERS
    // const combinedReminders = [
    //     ...(user_details.received_reminders || []),
    //     ...(user_details.self_reminders || []),
    //     ...(user_details.sent_reminders || [])
    // ];

    // user_details.reminders = combinedReminders

    setUserDetails(user_details)
    if (process.env.REACT_APP_MODE !== "production") {
        console.log(user_details)
    }
    // let channelJson = {}
    // // Need to finish the below line.  Check if user_details is undefined
    // // if (typeof user_details)
    // if (typeof user_details.channels !== 'undefined' && user_details.channels.length > 0) {
    //     // console.log("in the if")
    //     // if (user_details.channels.length > 0) {
    //         let tempJson = {}
    //         // let channelJson = {}
    //         // let channels = []
    //         // channelJson.channels = channels
    //         // console.log("length is " + user_details.channels.length)
    //         // console.log(user_details.channels)
    //         for (let i = 0; i < user_details.channels.length; i++) {
    //             tempJson[user_details.channels[i].service_name] = user_details.channels[i].params // this works
    //             // console.log("id is " + user_details.channels[i].id)
    //             channelJson[user_details.channels[i].service_name] = {
    //                 "params": user_details.channels[i].params,
    //                 "channel_id": user_details.channels[i].id,
    //                 "default": user_details.channels[i].default
    //             }

    //         }
    //         // console.log("tempJson is " + JSON.stringify(tempJson))
    //         // console.log(JSON.stringify(channelJson))
    //         // setUserChannelDetails(channelJson) // THIS WORKS
    //         // setChannelJson(channelJson)
    //     }
    // setUserChannelDetails(channelJson)

}

