import React from 'react';
import {Box, Center, Heading, Text, VStack} from "@chakra-ui/react"

function Privacy(){
    return (
        <Box pl={5} pr={5} pt={5}>
            <Text>
              <Heading fontSize={'4xl'}>Privacy Policy for ReminderLoop</Heading>
              <p>
                At ReminderLoop, accessible from www.reminderloop.com, one of our main
                priorities is the privacy of our visitors. This Privacy Policy document
                contains types of information that is collected and recorded by ReminderLoop
                and how we use it.
              </p>
              <p>
                If you have additional questions or require more information about our
                Privacy Policy, do not hesitate to contact us.
              </p>
              <Heading fontSize='2xl'>Log Files</Heading>
              <p>
                ReminderLoop follows a standard procedure of using log files. These files
                log visitors when they visit websites. All hosting companies do this and a
                part of hosting services' analytics. The information collected by log files
                include internet protocol (IP) addresses, browser type, Internet Service
                Provider (ISP), date and time stamp, referring/exit pages, and possibly the
                number of clicks. These are not linked to any information that is personally
                identifiable. The purpose of the information is for analyzing trends,
                administering the site, tracking users' movement on the website, and
                gathering demographic information. Our Privacy Policy was created with the
                help of the{" "}
                <a href="https://www.privacypolicyonline.com/privacy-policy-generator/">
                  Privacy Policy Generator
                </a>
                .
              </p>
              <Heading fontSize='2xl'>Cookies and Web Beacons</Heading>
              <p>
                Like any other website, ReminderLoop uses "cookies". These cookies are used
                to store information including visitors' preferences, and the pages on the
                website that the visitor accessed or visited. The information is used to
                optimize the users' experience by customizing our web page content based on
                visitors' browser type and/or other information.
              </p>
              <p>
                For more general information on cookies, please read{" "}
                <a href="https://www.privacypolicyonline.com/what-are-cookies/">
                  the "Cookies" article from the Privacy Policy Generator
                </a>
                .
              </p>

              <Heading fontSize='2xl'>Privacy Policies</Heading>
              <p>
                You may consult this list to find the Privacy Policy for each of the
                advertising partners of ReminderLoop.
              </p>
              <p>
                Third-party ad servers or ad networks uses technologies like cookies,
                JavaScript, or Web Beacons that are used in their respective advertisements
                and links that appear on ReminderLoop, which are sent directly to users'
                browser. They automatically receive your IP address when this occurs. These
                technologies are used to measure the effectiveness of their advertising
                campaigns and/or to personalize the advertising content that you see on
                websites that you visit.
              </p>
              <p>
                Note that ReminderLoop has no access to or control over these cookies that
                are used by third-party advertisers.
              </p>
              <Heading fontSize='2xl'>Third Party Privacy Policies</Heading>
              <p>
                ReminderLoop's Privacy Policy does not apply to other advertisers or
                websites. Thus, we are advising you to consult the respective Privacy
                Policies of these third-party ad servers for more detailed information. It
                may include their practices and instructions about how to opt-out of certain
                options.{" "}
              </p>
              <p>
                You can choose to disable cookies through your individual browser options.
                To know more detailed information about cookie management with specific web
                browsers, it can be found at the browsers' respective websites. What Are
                Cookies?
              </p>
              <Heading fontSize='2xl'>Children's Information</Heading>
              <p>
                Another part of our priority is adding protection for children while using
                the internet. We encourage parents and guardians to observe, participate in,
                and/or monitor and guide their online activity.
              </p>
              <p>
                ReminderLoop does not knowingly collect any Personal Identifiable
                Information from children under the age of 13. If you think that your child
                provided this kind of information on our website, we strongly encourage you
                to contact us immediately and we will do our best efforts to promptly remove
                such information from our records.
              </p>
              <Heading fontSize='2xl'>Online Privacy Policy Only</Heading>
              <p>
                This Privacy Policy applies only to our online activities and is valid for
                visitors to our website with regards to the information that they shared
                and/or collect in ReminderLoop. This policy is not applicable to any
                information collected offline or via channels other than this website.
              </p>
              <Heading fontSize='2xl'>Consent</Heading>
              <p>
                By using our website, you hereby consent to our Privacy Policy and agree to
                its Terms and Conditions.
              </p>
            </Text>

        </Box>
    )
}

export default Privacy;