import React from 'react';
import { 
  Box, 
  Flex, 
  IconButton, 
  useDisclosure, 
  Spacer, 
  HStack, 
  Text, 
  Drawer, 
  DrawerOverlay, 
  DrawerContent, 
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody, 
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import SettingsDrawer from './SettingsDrawer';

const TopBar = () => {
  const { isOpen: isSettingsOpen, onOpen: onSettingsOpen, onClose: onSettingsClose } = useDisclosure();
  let logoFontSize = {base: '30', sm: '30', md: '30', lg: '30', xl: '30', "2xl": "30"};

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      // padding="1rem"
      color="white"
      w={"100%"}
      bgGradient={'linear(to-tr, brand.pink, brand.violet 60%)'}
    >
      <Box w={'48px'} />
      <Spacer />
      <Box>
        <Link to="/">
          {/* Replace 'Logo' with your logo component */}
          <Box textStyle={'logo'} fontWeight={'bold'} pt={0} color='blackAlpha.700'>
            <HStack spacing={0}>
                <Text fontSize={logoFontSize}>Reminder</Text>
                <Text fontSize={logoFontSize}>L</Text>
                {/*<Box transform={"translateX(-.05rem) translateY(-0.15rem) rotate(-30deg)"}*/}
                <Box transform={"translateX(-3%) translateY(-0.15rem) rotate(-30deg)"} bgGradient={'linear(to-r, brand.o_beginning_logo, brand.o_ending_logo)'} bgClip={'text'}>
                    <Text as={'span'} mr={'-10%'} fontSize={logoFontSize} >o</Text>
                    <Text as={'span'} fontSize={logoFontSize} >o</Text>
                </Box>
                <Text  transform={'translateX(-20%)'} fontSize={logoFontSize}>p</Text>
            </HStack>
        </Box>
        </Link>
      </Box>
      <Spacer />
      <IconButton
        aria-label="Menu"
        size={'lg'}
        icon={<HamburgerIcon boxSize={6} />}
        onClick={onSettingsOpen}
        variant="ghost"
        _hover={{ bg: 'brand.violet_hover' }}
      />
      
      <Drawer isOpen={isSettingsOpen} placement="right" onClose={onSettingsClose} size={{base: 'xs', sm: 'xs', md: 'xs', lg: 'sm', xl: 'sm', "2xl": "sm"}}>
          <DrawerOverlay />
          <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader></DrawerHeader>
              <DrawerBody>
                  <SettingsDrawer />
              </DrawerBody>
          </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default TopBar;
