import React, { useState, useEffect, useContext } from 'react';
import {useNavigate} from "react-router-dom";
import {
    Box, Center, Text, VStack, Container, HStack, IconButton,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor, Flex, useDisclosure, Collapse, Button, Grid, GridItem,
} from "@chakra-ui/react"
import SettingStack from "./SettingStack";
import {UserContext} from "../UserContext";
import {InfoOutlineIcon, ExternalLinkIcon} from "@chakra-ui/icons";
import UserInputForm from "./UserInputForm";
import RemindersForOthersDisplay from "./RemindersForOthersDisplay";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";


function Settings(props){

    // let user_details = props.user_details
    // let setUserDetails = props.setUserDetails
    // let setChannelsLoaded = props.setChannelsLoaded
    let navigate = useNavigate()
    const user = useContext(UserContext)
    let user_details = user.user_details
    // let userIsLoaded = props.userIsLoaded
    // let setUserIsLoaded = props.setUserIsLoaded
    // let channels = props.channels
    // let setChannels = props.setChannels
    let user_channel_details = user.user_channel_details
    // let setUserChannelDetails = props.setUserChannelDetails
    let setNeedsRefresh = user.setNeedsRefresh
    // console.log(user_channel_details)
    // console.log(user_details)
    const { isOpen: userSettingsIsOpen,
            onToggle: userSettingsOnToggle } = useDisclosure()
    const { isOpen: channelSettingsIsOpen,
            onToggle: channelSettingsOnToggle } = useDisclosure()
    const { isOpen: othersSettingsIsOpen,
            onToggle: othersSettingsOnToggle } = useDisclosure()
    // const name = user_details.first_name + " " + user_details.last_name
    // console.log(name)

    function handleBillingAndSubscriptionClick(){
        let url = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL + '?prefilled_email=' + encodeURIComponent(user_details["email"])
        window.location.replace(url);
    }

    return (
        // <Box bgGradient={'linear(to-tr, #d4a4e0, #f7a2cb, #ffa6b0, #ffb295, #ffc484, #f6d080, #e9dd82, #d8ea8a, #c0ec9e, #adedb2, #a1ebc5, #9de8d4)'}>
        // <Box bgGradient={'linear(to-tr, brand.pink, brand.yellow)'}>
        // <Box bgGradient={'linear(50deg, brand.violet 30%, brand.green)'}>
        <Box w={'100%'} pb={4} bgGradient={'linear(50deg, brand.violet 30%, brand.green)'}>
            {/*<VStack spacing={'10px'}>*/}
            <Center>
                <Text fontSize='4xl' color={'white'}>Hello, {user_details["first_name"]}!</Text>
            </Center>
                <Grid gridTemplateColumns={'1fr 1fr'}>
                    <GridItem rowSpan={1} colSpan={1} align={'right'} pt={2} pb={2}>
                        <Text color={'white'} fontSize={'2xl'} onClick={handleBillingAndSubscriptionClick}>Subscription & Billing</Text>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={1} align={'center'} pb={2}>
                        <IconButton bg={'brand.orange'} color={'white'} aria-label={'Subscription and Billing Link'} onClick={handleBillingAndSubscriptionClick} icon={<ExternalLinkIcon/>}/>
                    </GridItem>
                {/*---------------------------------*/}
                    <GridItem rowSpan={1} colSpan={1} align={'right'} pt={2}>
                        <Text color={'white'} fontSize={'2xl'} onClick={userSettingsOnToggle}>User Settings</Text>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={1} align={'center'} pb={2}>
                        <IconButton bg={'brand.orange'} color={'white'} onClick={userSettingsOnToggle} aria-label={'expand user settings'} icon={<KeyboardArrowDownOutlinedIcon/>} />
                    </GridItem>
                    <GridItem colSpan={2} bg={'white'}>
                        <Collapse in={userSettingsIsOpen} animateOpacity w={'100%'}>
                            <UserInputForm name={""} page={null} setPage={null} onboarding={false}/>
                        </Collapse>
                    </GridItem>
                {/*---------------------------------*/}
                    <GridItem rowSpan={1} colSpan={1} pt={2}>
                        <Popover>
                            <HStack justify={'right'}>
                                <Text color={'white'} fontSize={'2xl'} onClick={channelSettingsOnToggle}>Channel Settings</Text>
                                <PopoverTrigger>
                                    <Flex >
                                        <InfoOutlineIcon color={'white'}/>
                                    </Flex>
                                </PopoverTrigger>
                            </HStack>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader>Channels</PopoverHeader>
                                <PopoverBody>Channels are the ways that you receive reminders. The default way to receive a reminder is through the email that you signed up with. You can add more channels by clicking on the channel title and inputting the information for that channel. Each channel offers a button you can click for additional information on how to set it up.
                                    Set your default channel with the bottons on the left side of the screen.
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={1} align={'center'} pb={2} pt={2}>
                        <IconButton bg={'brand.orange'} color={'white'} onClick={channelSettingsOnToggle} aria-label={'expand user settings'} icon={<KeyboardArrowDownOutlinedIcon/>} />
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={2} bg={'white'}>
                        <Collapse in={channelSettingsIsOpen} animateOpacity w={'100%'}>
                            {/* <SettingStack channels={channels}/> */}
                        </Collapse>
                    </GridItem>

                {/*---------------------------------*/}
                    <GridItem rowSpan={1} colSpan={1} align={'right'} pt={2}>
                        <Text color={'white'} fontSize={'2xl'} onClick={othersSettingsOnToggle}>Reminders for Others</Text>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={1} align={'center'} pb={2} pt={2}>
                        <IconButton bg={'brand.orange'} color={'white'} onClick={othersSettingsOnToggle} aria-label={'expand user settings'} icon={<KeyboardArrowDownOutlinedIcon/>} />
                    </GridItem>
                    <GridItem colSpan={2} bg={'white'}>
                        <Collapse in={othersSettingsIsOpen} animateOpacity w={'100%'}>
                            {/*<UserInputForm page={null} setPage={null} onboarding={false}/>*/}
                            <RemindersForOthersDisplay />
                        </Collapse>
                    </GridItem>
                </Grid>
        </Box>
    )
}


export default Settings;