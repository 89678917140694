import React, {useEffect, useState} from 'react';
import {
    Button,
    Wrap,
    WrapItem,
    Text,
    OrderedList,
    ListItem,
    VStack,
    Link,
    Image,
    Code,
    Container, Center
} from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useClipboard } from '@chakra-ui/react'


function Instructions(props){
    let setInstructionsNeeded = props.setInstructionsNeeded;
    let service = props.service;
    let channels = props.channels;
    const [service_url, setService_url] = useState(null);
    const { onCopy, value, setValue, hasCopied } = useClipboard("");

    useEffect(() => {
        for (let i = 0; i < channels.length; i++){
            if (channels[i].service_name === service){
                setService_url(channels[i].service_url)
            }
        }
    }, [channels, service])

    // console.log(channels)

    function handleDone(){
        setInstructionsNeeded(null);
    }

    if (service === "Pushover") {
        return (
            <WrapItem background={'gray.100'}>
                <VStack align={'left'}>
                    <Center><Text as={'h1'} fontSize={'xl'} fontWeight={'bold'} pb={3}>How to Set Up {service}</Text></Center>
                    <OrderedList spacing={4} pb={10} pl={5}>
                        <ListItem><Container>Go to <Link href='https://pushover.net/' color={'teal.500'} isExternal>
                            https://pushover.net/<ExternalLinkIcon mx={'2px'} /></Link> and Login or Signup.</Container></ListItem>
                        <ListItem><Container>Copy your User Key from your Pushover dashboard:
                            <Image src={'./pushover_user_key.png'} alt={'Pushover User Key'} /></Container>
                        </ListItem>
                        <ListItem><Container>Leave Access Token blank to use ours. Alternatively,
                        you could <Link href='https://pushover.net/apps/build' color={'teal.500'} isExternal>
                                create your own application and API token<ExternalLinkIcon mx={'2px'} /></Link>.</Container></ListItem>
                        <ListItem><Container><Text as={'i'}>Optional: Add any additional settings to target your notifications.</Text></Container></ListItem>
                        <ListItem><Container>Download the <Link href='https://pushover.net/clients' color={'teal.500'} isExternal>
                                Pushover client<ExternalLinkIcon mx={'2px'} /></Link> for your device.</Container></ListItem>
                        <ListItem><Container>Set Pushover as default.</Container></ListItem>
                    </OrderedList>
                    <Button onClick={handleDone} colorScheme={'blue'}>Done</Button>
                </VStack>
            </WrapItem>
        )
    }
    if (service === "Pushbullet") {
        return (
            <WrapItem background={'gray.100'}>
                <VStack align={'left'}>
                    <Center><Text as={'h1'} fontSize={'xl'} fontWeight={'bold'} pb={3}>How to Set Up {service}</Text></Center>
                    <OrderedList spacing={4} pb={10} pl={5}>
                        <ListItem><Container>Go to <Link href='https://www.pushbullet.com/' color={'teal.500'} isExternal>
                            https://www.pushbullet.com/<ExternalLinkIcon mx={'2px'} /></Link> and Login or Signup.</Container></ListItem>
                        <ListItem><Container>Click on Settings</Container></ListItem>
                        <ListItem><Container>Click on Create Access Token</Container></ListItem>
                        <ListItem><Container>Copy your Access Token from your Pushbullet account:
                            <Image src={'./pushbullet_access_token.png'} alt={'Pushbullet Access Token'} /></Container>
                        </ListItem>
                        <ListItem><Container><Text as={'i'}>Optional: Add any additional settings to target your notifications.</Text></Container></ListItem>
                        <ListItem><Container>Download the <Link href='https://www.pushbullet.com/apps' color={'teal.500'} isExternal>
                                Pushbullet client<ExternalLinkIcon mx={'2px'} /></Link> for your device.</Container></ListItem>
                        <ListItem><Container>Set Pushbullet as default.</Container></ListItem>

                    </OrderedList>
                    <Button onClick={handleDone} colorScheme={'blue'}>Done</Button>
                </VStack>
            </WrapItem>
        )
    }


}

export default Instructions;