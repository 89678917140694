// import React, { useContext } from 'react';
import { useParams, Navigate, useNavigate } from "react-router-dom";
import {Box, Center, Heading} from '@chakra-ui/react'
// import {useSessionContext} from "supertokens-auth-react/recipe/session";
// import PricingTable from "./PricingTable";
// import { UserContext } from "../UserContext";
import DecorativePricingTable from './DecorativePricingTable';


function PayPage(props) {
    // const user = useContext(UserContext)
    // let user_details = user.user_details

    // if (user_details.is_active) {
    //     return <Navigate to="/settings" />
    // }

    return (
        <Box pb={9} bg={'brand.green'}>
            <Center>
                <Heading pb={2}>Select your plan</Heading>
            </Center>
            {/* <PricingTable /> */}
            <DecorativePricingTable navToHome={true} />
            
        </Box>
    );
}

export default PayPage;