import React from 'react'
import {Box, Button, Center, Flex, Grid, HStack, Icon, Spacer, Text, VStack, Link} from "@chakra-ui/react";
// import TwitterIcon from '@mui/icons-material/Twitter';
import XIcon from '@mui/icons-material/X';
import {NavLink} from "react-router-dom";

function Footer(props) {

    return (

        <Box id='footer' w={'100%'} h={'200px'} bg={'#2b272e'} pt={10}>
            <Center>
                <Box w={['87%','80%']} borderTopColor={'brand.pink'} borderTopWidth={2}>
                    <Grid templateColumns={'repeat(4, 1fr)'} gap={6} pt={4}>
                        <Box>
                            <Text minW={'fit-content'} color={'brand.pink'} fontSize={['3xl','3xl','4xl']}
                                  fontWeight={'bold'} sx={{
                                      'white-space': 'nowrap',
                            }}>Say hello</Text>
                        </Box>
                        <Box>

                        </Box>
                        <Box >
                            <Text color={'brand.pink'} fontSize={['md','md','lg']} fontWeight={'bold'}>EMAIL</Text>
                            <Link href='mailto:hello@reminderloop.com' color={'brand.pink'} fontSize={['xs','xs','sm']}>hello@reminderloop.com</Link>
                        </Box>
                        <Box>
                            <Center><Icon as={XIcon} w={[7,7,9]} h={[7,7,9]} color={'brand.pink'}/></Center>
                        </Box>
                    </Grid>
                </Box>
            </Center>
            <Center pt={10} pl={2} pr={2}>
                <Text color={'brand.pink'} fontSize={'sm'}>
                    Use of this site constitutes acceptance of our <Link as={NavLink} to={'terms'}>Terms of Use</Link> and <Link as={NavLink} to={'privacy'}>Privacy Policy</Link>. © 2024 ReminderLoop LLC. All rights reserved.
                </Text>
            </Center>

        </Box>

    )
}

export default Footer