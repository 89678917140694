import React from 'react';
import {
    Box,
    Icon,
    Text,
    Heading,
    Image,
    Button,
    Divider,
    ModalOverlay,
    ModalContent,
    ModalCloseButton, ModalBody, HStack, ModalHeader, ListItem, Flex, Modal, createIcon, VStack, Spacer, useBreakpointValue
} from '@chakra-ui/react';

import MemoryRoundedIcon from '@mui/icons-material/MemoryRounded';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import EmojiNatureRoundedIcon from '@mui/icons-material/EmojiNatureRounded';
import MobileFriendlyRoundedIcon from '@mui/icons-material/MobileFriendlyRounded';
import PrecisionManufacturingRoundedIcon from '@mui/icons-material/PrecisionManufacturingRounded';
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded';
import { useColorModeValue } from "@chakra-ui/react";

// const iconSettings = {
//     fontSize: 'large',
//     color: 'black.alpha.700',
// };

const features = [
    { title: 'Simplify Reminder Setting with AI', description: "Just say what you need to remember, and our AI handles the setup. Forget fumbling with dates and times—speak naturally, and you're all set.", icon: MemoryRoundedIcon },
    { title: 'Connect with Loved Ones', description: "Unlock the power to share reminders with your family and friends with a premium account. Simplify group tasks or just help each other remember important stuff.", icon: GroupAddRoundedIcon },
    { title: 'Access Anywhere, Anytime', description: "Stay in sync with our mobile and web apps. Your reminders follow you, no matter where you are or what device you're using.", icon: EmojiNatureRoundedIcon },
    { title: 'Personalized Notification Options', description: "Choose how you receive reminders to fit your lifestyle. Whether on web or mobile, you control how you're notified.", icon: MobileFriendlyRoundedIcon },
    { title: 'Manual Edits & Feedback Welcome', description: "We're only human (well the AI isn't, but it was made by humans) so sometimes we get it wrong. Edit your reminder and send us feedback so we can get it right next time!", icon: PrecisionManufacturingRoundedIcon },
    { title: 'Handcrafted with Care', description: "ReminderLoop isn't just an app; it's a passion project brought to life by a dedicated creator. Supporting ReminderLoop means you're backing a small business with a big heart. Learn more about the journey behind the scenes and why every reminder is sent with love.", icon: VolunteerActivismRoundedIcon },
];

const featureDescriptionFontSize = {lg: "sm", xl: "md", "2xl": "lg"};

// rgba(0, 0, 0, 0.6)

const createFeatureBoxes = () => {
    return features.map((feature, index) => (
        <Box key={index} pb={{lg: 2, xl: 3, "2xl": 5}}>
            <Flex pb={0} direction={'row'} align={'center'}>
                <Icon as={feature.icon} boxSize={7} color={'brand.violet_text'} />
                <Heading 
                    pl={3} 
                    as={'h2'} 
                    size={{lg: "sm", xl: "md", "2xl": "md"}} 
                    color={'brand.violet_text'}
                >
                    {feature.title}
                </Heading>
            </Flex>
            <Box pl={10}>
                <Text fontSize={featureDescriptionFontSize} fontWeight={'semibold'} color={'blackAlpha.700'}>{feature.description}</Text>
            </Box>
        </Box>
    ));
};

const Features = () => {

    return (
        <div>
            <Divider pt={3}/>
            <Spacer h={3}/>
            {createFeatureBoxes()}
        </div>
    );
}

export default Features;