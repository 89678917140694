import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Center,
    Text,
    Heading,
    Input,
    Button,
    Icon,
    useToast,
    HStack,
    Avatar,
    ModalContent,
    ModalCloseButton, ModalBody, ModalHeader, ModalOverlay, ModalFooter, Divider, Flex, Modal, VStack, Spacer, useDisclosure
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Session from "supertokens-auth-react/recipe/session";
import {UserContext} from "../UserContext";
import DoneIcon from '@mui/icons-material/Done';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PaymentIcon from '@mui/icons-material/Payment';
import HelpIcon from '@mui/icons-material/Help';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
// import DecorativePricingTable from './DecorativePricingTable';

function SettingsDrawer({openSignUpModal, onSettingsClose}) {
    const user = useContext(UserContext);
    let user_details = user.user_details;
    const [name, setName] = useState(user_details.first_name + ' ' + user_details.last_name);
    const [email, setEmail] = useState(user_details.email);
    const [firstName, setFirstName] = useState(user_details.first_name);
    const [lastName, setLastName] = useState(user_details.last_name);
    const [isSaving, setIsSaving] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure(); // Modal control
    const paymentSectionIcon = user_details.account_type === 'free' ? StarIcon : PaymentIcon;
    const paymentSectionLabel = user_details.account_type === 'free' ? 'Upgrade Now!' : 'Billing & Subscription';
    const paymentSectionFunction = user_details.account_type === 'free' ? handleUpgradeClick : handlePaymentsAndSubClick;
    const navigate = useNavigate();
    const toast = useToast();

    const settingsSections = [
        { icon: DoneIcon, label: 'Previous Reminders', onClick: handlePreviousReminderClick },
        { icon: EditNotificationsIcon, label: 'Notification Settings', onClick: handleNotificationsClick },
        { icon: GroupAddIcon, label: 'Reminders For Others', onClick: handleRemindersForOthersClick },
        { icon: paymentSectionIcon, label: paymentSectionLabel, onClick: paymentSectionFunction },
        { icon: HelpIcon, label: 'Help & Support', onClick: handleHelpClick },
    ];
    
    useEffect(() => {
        setName(user_details.first_name + ' ' + user_details.last_name);
        setEmail(user_details.email);
    }, [user_details]);

    async function onLogout() {
        await signOut();
        window.location.href = "/";
    }

    function handlePreviousReminderClick() {
        // console.log('Previous Reminders');
        // navigate to /reminders
        navigate('/reminders');
    }

    function handleNotificationsClick() {
        // console.log('Notification Settings');
        navigate('/notifications');
    }

    function handleRemindersForOthersClick() {
        // console.log('Reminders For Others');
        navigate('/remindersforothers');
    }

    function handlePaymentsAndSubClick() {
        if (user_details.sub_payment_service === 'stripe') {
            let url = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL + '?prefilled_email=' + encodeURIComponent(user_details["email"])
            window.location.replace(url);
        } else if (user_details.sub_payment_service === 'google') {
            let url = "https://myaccount.google.com/payments-and-subscriptions"
            window.location.replace(url);
        } else if (user_details.sub_payment_service === 'apple') {
            let url = "https://apps.apple.com/account/subscriptions"
            window.location.replace(url);
        } else {
            console.log('No payment service found');
        }
    }

    function handleHelpClick() {
        // console.log('Help & Support');
        navigate('/help');
    }

    function handleUpgradeClick() {
        onSettingsClose();
        openSignUpModal();
    }

    function handleFirstNameChange(event) {
        setFirstName(event.target.value);
    }

    function handleLastNameChange(event) {
        setLastName(event.target.value);
    }

    let handleNameSubmit = async (e) => {
        setIsSaving(true);
        let obj = {
            supertokens_id: user_details.supertokens_id,
            first_name: firstName,
            last_name: lastName
        };
        try{
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/update-name", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            });
            if (res.status === 200){
                setName(`${firstName} ${lastName}`);
                toast({
                    title: "Name updated successfully",
                    status: "success",
                    isClosable: true
                });
                user.setNeedsRefresh(true);

            } else {
                toast({
                    title: "Error updating name",
                    status: "error",
                    isClosable: true
                });
            }
        } catch (err) {
            console.log('Error updating name ', err);
        }
        setIsSaving(false);
        onClose();
    }

    return (
        <Flex pt={8} direction={'column'} justify={'space-between'} h={'100%'}>
            <HStack pb={5}>
                <Avatar name={name} src={user_details.avatar_url} /> 
                <VStack pl={4} spacing={0} align={'start'}>
                    <HStack>
                        <Heading size={'md'}>{name}</Heading>
                        <Icon as={EditIcon} color={'blackAlpha.600'} cursor="pointer" onClick={onOpen} />
                    </HStack>
                    <Text>{email}</Text>
                </VStack>
            </HStack>
            <Divider />
            <VStack align="start" spacing={4} mt={4}>
                {settingsSections.map((section, index) => (
                    <HStack key={index} spacing={4} onClick={section.onClick} cursor="pointer">
                        <Icon as={section.icon} color={'blackAlpha.800'}/>
                        <Heading size={'sm'} color={'blackAlpha.800'}>{section.label}</Heading>
                    </HStack>
                ))}
            </VStack>
            <Spacer />
            <Button 
                bg={'brand.red'} 
                onClick={() => onLogout()}
                _hover={{ bg: 'brand.red_hover' }}
            >Log Out</Button>

            {/* Modal for editing name */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Name</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    <Input placeholder="First Name" value={firstName} onChange={handleFirstNameChange} />
                    <Input placeholder="Last Name" value={lastName} onChange={handleLastNameChange} />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleNameSubmit} isLoading={isSaving}>
                            Save
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    )
}

export default SettingsDrawer;