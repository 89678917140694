import React, { useState, useEffect, useContext, useRef } from 'react';
import {
    useToast,
    Textarea,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Center,
    Spacer,
    Text,
    HStack,
    Flex,
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    VStack,
    Button,
    Heading,
    Input, ButtonGroup, Divider, Grid, GridItem, Spinner, Avatar, Icon, useBreakpointValue, Select, Menu, MenuButton, MenuList, MenuItem, IconButton, Wrap
} from "@chakra-ui/react"
import {UserContext} from "../UserContext";
import TopBar from './TopBar';
import { useSessionContext } from "supertokens-auth-react/recipe/session"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import SortIcon from '@mui/icons-material/Sort';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const options = ["From", "Self", "To"];


const SegmentedControl = ({ onSelectionChange, selectedOptions, setSelectedOptions }) => {
  

  const toggleOption = (option) => {
    const currentIndex = selectedOptions.indexOf(option);
    const newSelectedOptions = [...selectedOptions];

    if (currentIndex === -1) {
      newSelectedOptions.push(option);
    } else {
      newSelectedOptions.splice(currentIndex, 1);
    }

    setSelectedOptions(newSelectedOptions);
    onSelectionChange(newSelectedOptions);
  };

  return (

    <ButtonGroup isAttached>
        {options.map((option, index) => (
            <Button
                key={option}
                w={{lg: "60px", xl: "65px", "2xl": "65px"}}
                h={{lg: "35px", xl: "40px", "2xl": "40px"}}
                bg={selectedOptions.includes(option) ? "brand.green" : "gray.300"}
                onClick={() => toggleOption(option)}
                borderRadius={index === 0 ? 'full' : index === options.length - 1 ? 'full' : '0'}
                // Apply a left radius to the first item and a right radius to the last item
                {...(index === 0 && {borderTopLeftRadius: 'full', borderBottomLeftRadius: 'full'})}
                {...(index === options.length - 1 && {borderTopRightRadius: 'full', borderBottomRightRadius: 'full'})}
                // Apply a right border to all but the last item
                borderRight={index !== options.length - 1 ? '1px' : 'none'}
                borderColor="gray.200" // Use a border color that suits your design
            >
                <Text fontSize={{lg: "sm", xl: "md", "2xl": "md"}} fontWeight={'bold'} color={"blackAlpha.700"}>
                    {option}
                </Text>
            </Button>
        ))}
    </ButtonGroup>

  );
};


function Reminders(props){
    // const [isLoading, setIsLoading] = useState(true);
    const [isOverflowed, setIsOverflowed] = useState(false);
    const listRef = useRef(null);
    const user = useContext(UserContext);
    let user_details = user.user_details;
    const session = useSessionContext();
    let [reminderList, setReminderList] = useState([])
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isFlagModalOpen, setIsFlagModalOpen] = useState(false);
    // const [reminderUuidToEdit, setReminderUuidToEdit] = useState(null);
    const [reminderUuidToFlag, setReminderUuidToFlag] = useState(null);
    const [editReminderAction, setEditReminderAction] = useState('');
    const [editFireDate, setEditFireDate] = useState('');
    const [nowDateTimeString, setNowDateTimeString] = useState('');
    const [feedbackText, setFeedbackText] = useState('');
    const [feedbackLoading, setFeedbackLoading] = useState(false);

    const openEditModal = () => setIsEditModalOpen(true);
    const closeEditModal = () => setIsEditModalOpen(false);
    const openFlagModal = () => setIsFlagModalOpen(true);
    const closeFlagModal = () => setIsFlagModalOpen(false);

    const toFromfontSize = useBreakpointValue({ lg: "sm", xl: "sm", "2xl": "md" });
    const [selectedOptions, setSelectedOptions] = useState([...options]);

    const [sortOrder, setSortOrder] = useState('actionAsc'); // Default sorting order

    const toast = useToast();


    useEffect(() => {
        // console.log("loading is " + user.loading)
        // Start with empty arrays for each category
        let receivedReminders = [];
        let selfReminders = [];
        let sentReminders = [];

        if (user_details) {
            // console.log("user_details is " + user_details)
          // Start with empty arrays for each category
        //   let receivedReminders = [];
        //   let selfReminders = [];
        //   let sentReminders = [];
      
          // If 'From' is selected, filter the received reminders
          if (selectedOptions.includes("From")) {
            receivedReminders = user_details.received_reminders || [];
          }
      
          // If 'Self' is selected, filter the self reminders
          if (selectedOptions.includes("Self")) {
            selfReminders = user_details.self_reminders || [];
          }
      
          // If 'To' is selected, filter the sent reminders
          if (selectedOptions.includes("To")) {
            sentReminders = user_details.sent_reminders || [];
          }
      
          // Combine the filtered reminders
          const combinedReminders = [ // this is line 132
            ...receivedReminders,
            ...selfReminders,
            ...sentReminders
          ];

          // Sort the combined reminders based on the sortOrder state
            combinedReminders.sort((a, b) => {
                if (sortOrder === 'actionAsc') {
                    return a.reminder_action.localeCompare(b.reminder_action);
                } else if (sortOrder === 'actionDesc') {
                    return b.reminder_action.localeCompare(a.reminder_action);
                } else if (sortOrder === 'dateAsc') {
                    return new Date(a.local_fire_date_time) - new Date(b.local_fire_date_time);
                } else if (sortOrder === 'dateDesc') {
                    return new Date(b.local_fire_date_time) - new Date(a.local_fire_date_time);
                } else if (sortOrder === 'addedAsc') {
                    return new Date(b.date_added) - new Date(a.date_added);
                } else if (sortOrder === 'addedDesc') {
                    return new Date(a.date_added) - new Date(b.date_added);
                }
            });
      
          setReminderList(combinedReminders);
        //   setIsLoading(false);
        }
    }, [user_details, selectedOptions, sortOrder, user.loading]); // Add selectedOptions as a dependency
      

    useEffect(() => {
        const checkOverflow = () => {
          const current = listRef.current;
          if (!current) {
            return;
          }
    
          // Set state based on overflow
          setIsOverflowed(current.clientHeight < current.scrollHeight);
        };
    
        // Run on mount and every time the list changes
        checkOverflow();
    
        // Optional: if you expect dynamic content changes or window resizing
        window.addEventListener('resize', checkOverflow);

        // console.log("isOverFlowed is " + isOverflowed)
    
        // Cleanup event listener
        return () => window.removeEventListener('resize', checkOverflow);
    }, [reminderList]); 

    function buildToFromBox(reminder){
        if (reminder.owner_id !== user_details.supertokens_id){
            //build receiver icon
            return (
                <Box bg={'brand.green'} pr={2} pl={2} borderRadius={'full'}>
                    <HStack>
                        <Text color={'blackAlpha.700'} fontWeight={'bold'}>
                            From
                        </Text>
                        <Avatar size='2xs' name='Dan Abrahmov' src='https://bit.ly/dan-abramov' />
                    </HStack>
                </Box>
            )
        }
        else if (reminder.target_user_id !== user_details.supertokens_id){
            // build sender icon
            return (
                <Box bg={'brand.green'} pr={2} pl={2} borderRadius={'full'}>
                    <HStack>
                        <Text color={'blackAlpha.700'} fontWeight={'bold'}>
                            To
                        </Text>
                        <Avatar size='2xs' name='Dan Abrahmov' src='https://bit.ly/dan-abramov' />
                    </HStack>
                </Box>
            )
        }
        else {
            // return empty box
            return (
                <Box></Box>
            )
        }
    }

    // Handler for selecting a sort option
    const handleSort = (order) => {
        setSortOrder(order);
    };

    function handleFlag(uuid){
        setReminderUuidToFlag(uuid);
        openFlagModal();
    }

    function handleFlagClose(){
        setReminderUuidToFlag(null);
        setFeedbackText('');
        closeFlagModal();
    }

    async function handleFlagSubmit(){
        setFeedbackLoading(true);
        let obj = {}
        obj.supertokens_id = user_details.supertokens_id;
        obj.reminder_uuid = reminderUuidToFlag;
        obj.feedback_text = feedbackText;
        try{
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/reminder-feedback", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
            if (res.status ===200) {
                // console.log("default channel updated successfully");
                // console.log(e)
                toast({
                    title: "Feedback Submitted!",
                    status: "success",
                    isClosable: true
                })
            } else {
                console.log("some error occurred");
                // logtail.error(user_details.email + ": some error occurred while setting default channel")
                // logtail.flush()
                toast({
                    title: "some error occurred",
                    status: "error",
                    isClosable: true
                })
            }
            setReminderUuidToFlag(null);
        } catch (err) {
            // logtail.error(user_details.email + ": some error occurred while setting default channel", err)
            // logtail.flush()
            console.log(err);
            
        }
        setFeedbackText('');
        setReminderUuidToFlag(null);
        setFeedbackLoading(false);
        closeFlagModal();
        
    }

    function handleSetAgain(uuid){
        toast({
            title: "Coming Soon!",
            description: "We're working on this feature and it will be available soon.",
            status: "info",
            duration: 4000,
            isClosable: true,
        })

    }

    

    function handleDelete(e) {
        // // console.log(e.target.parentElement.id)
        // // console.log(e.currentTarget.id + " clicked")
        // const reminderIdToDelete = e.currentTarget.id;

        // // Filter out the reminder that needs to be deleted using its uuid
        // const updatedReminders = reminderList.filter(reminder => reminder.uuid !== reminderIdToDelete);

        // // Update the state with the filtered reminders list
        // setReminderList(updatedReminders);
        
    }

    const handleSelectionChange = (selectedOptions) => {
        console.log(selectedOptions);
        // const updatedList = reminderList.filter((reminder) =>
        //   selectedOptions.includes(reminder.type) // assuming each reminder has a type
        // );
        // setReminderList(updatedList);
      };

    const buildReminder = (reminder) => {
        const date = new Date(reminder.local_fire_date_time);
        const formattedDate = date.toLocaleString('en-US', {
            month: 'long', // Display the full month name
            day: 'numeric', // Display the day as a number
            year: 'numeric', // Display the full year
            hour: 'numeric', // Display the hour
            minute: '2-digit', // Display the minute with at least 2 digits
            // second: '2-digit', // Display the second with at least 2 digits
        });
        
        return (
            // <Accordion 
            //     allowToggle 
            //     border={"0px"}
            //     // onChange={handleExpandedReminder(reminder.uuid)}
            // >
            <>
                <AccordionItem key={reminder.uuid} border={"0px"} pb={1}>
                    {({ isExpanded }) => (
                        <>
                            <Grid
                                templateColumns={'repeat(20, 1fr)'}
                                templateAreas={`"action action action action action action action action action action action action action action tofrom tofrom tofrom tofrom accordion accordion"
                                                "time time time time time time time time time time time time time time tofrom tofrom tofrom tofrom accordion accordion"`}
                                // templateRows='repeat(2, 1fr)'
                                // templateColumns='repeat(20, 1fr)'
                                gap={0}
                                bg={'whiteAlpha.700'}
                                // borderRadius={isExpanded ? "3xl 3xl 0 0" : "3xl"}
                                borderTopRadius={"3xl"}
                                borderBottomRadius={isExpanded ? "0" : "3xl"}
                                
                                >
                                {/* <GridItem
                                    // bg={'red.100'}
                                    area={'check'}
                                    display="flex" // This enables the flexbox layout
                                    justifyContent="center" // This centers the content horizontally
                                    alignItems="center" // This centers the content vertically
                                >
                                    <Center>
                                        <Icon 
                                            as={CheckCircleOutlineIcon} 
                                            id={reminder.uuid} 
                                            onClick={handleDelete} 
                                            boxSize={{lg: 8, xl: 10, "2xl": 12}}
                                            style={{color: 'grey', cursor: 'pointer'}}
                                        />
                                    </Center>
                                </GridItem> */}
                                
                                <GridItem bg={''} area={'action'}>
                                    <Text isTruncated minWidth={"0"} fontSize={{lg: "lg", xl: "xl", "2xl": "2xl"}} color={'blackAlpha.800'}>{reminder.reminder_action}</Text>
                                </GridItem>
                            
                                <GridItem bg={''} area={'time'}>
                                    <Text fontSize={{lg: "sm", xl: "sm", "2xl": "md"}} color={'blackAlpha.800'}>{formattedDate}</Text>
                                </GridItem>
                                <GridItem
                                    area={'tofrom'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    {buildToFromBox(reminder)}
                                </GridItem>
                                <GridItem
                                    // bg={'red.100'}
                                    id='accordion-button-grid'
                                    area={'accordion'}
                                    display="flex" // This enables the flexbox layout
                                    justifyContent="center" // This centers the content horizontally
                                    alignItems="center" // This centers the content vertically
                                >
                                    <AccordionButton >
                                        <Center id='accordion-button-center' w={"100%"}>
                                            <AccordionIcon />
                                        </Center>
                                    </AccordionButton>
                                </GridItem>
                                
                            </Grid>
                            <AccordionPanel
                                bg={'whiteAlpha.700'}
                                borderBottomRadius={"3xl"}
                                pb={2}
                            >
                                <Flex direction={"row"}>
                                    <Spacer />
                                    <Button
                                        size={{base: 'sm', sm: 'sm', md: 'sm', lg: "sm", xl: "md", "2xl": "md"}} 
                                        leftIcon={<RestartAltIcon style={{color: 'white'}} />} 
                                        bg={'brand.violet'}
                                        onClick={() => handleSetAgain(reminder.uuid)} 
                                        _hover={{
                                            bg: 'brand.violet_hover', // darken the button on hover
                                            // transform: 'scale(1.05)', // scale up the button on hover
                                            boxShadow: 'xl', // even larger shadow on hover
                                        }}   
                                    >
                                        <Text color={'white'} fontWeight={'bold'}>
                                            Set Reminder Again
                                        </Text>
                                    </Button>
                                    <Spacer />
                                    <Button 
                                        size={{base: 'sm', sm: 'sm', md: 'sm', lg: "sm", xl: "md", "2xl": "md"}} 
                                        leftIcon={<FlagRoundedIcon style={{color: 'white'}} />} 
                                        bg={'brand.violet'}
                                        onClick={() => handleFlag(reminder.uuid)}
                                        _hover={{
                                            bg: 'brand.violet_hover', // darken the button on hover
                                            // transform: 'scale(1.05)', // scale up the button on hover
                                            boxShadow: 'xl', // even larger shadow on hover
                                        }}
                                    >
                                        <Text color={'white'} fontWeight={'bold'}>
                                            Flag a problem
                                        </Text>
                                    </Button>
                                    <Spacer />
                                </Flex>
                                
                            </AccordionPanel>
                        </>
                    )}
                </AccordionItem>
                <Divider />
            </>
            //</Accordion>

        );
    }
    if (user.loading) {
        return (
            <Center h={"calc(100vh - 256px)"}>
                <Spinner size="xl" />
            </Center>
        );
    }

    return (
        <>
            <TopBar />
            <Center>
                <Flex 
                    pt={5} 
                    w={{base: '90%', sm: '90%', md: '90%', lg: '100%', xl: '100%', '2xl': '100%'}} 
                    justify={'center'}
                    minH={"calc(100vh - 248px)"}
                >
                <VStack>
                    <Heading pb={5}>Previous Reminders</Heading>
                    <HStack width="full" justifyContent="center" alignItems="center">
                        <Box width={'40px'} />
                        <Spacer />
                        <SegmentedControl onSelectionChange={handleSelectionChange} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions}/>
                        <Spacer />
                        <Menu>
                            <MenuButton 
                                as={IconButton} 
                                aria-label="Sort" 
                                icon={<SortIcon />} 
                                variant="soloid" 
                                bg={'brand.orange'} 
                                borderRadius={'full'}
                                _hover={{bg: 'brand.orange_hover'}}
                            />
                            <MenuList>
                                <MenuItem onClick={() => handleSort('actionAsc')} fontWeight={sortOrder === 'actionAsc' ? 'bold' : 'normal'}>Action (A-Z)</MenuItem>
                                <MenuItem onClick={() => handleSort('actionDesc')} fontWeight={sortOrder === 'actionDesc' ? 'bold' : 'normal'}>Action (Z-A)</MenuItem>
                                <MenuItem onClick={() => handleSort('dateAsc')} fontWeight={sortOrder === 'dateAsc' ? 'bold' : 'normal'}>Notification Date (soonest)</MenuItem>
                                <MenuItem onClick={() => handleSort('dateDesc')} fontWeight={sortOrder === 'dateDesc' ? 'bold' : 'normal'}>Notification Date (latest)</MenuItem>
                                <MenuItem onClick={() => handleSort('addedAsc')} fontWeight={sortOrder === 'addedAsc' ? 'bold' : 'normal'}>Date Added (newest)</MenuItem>
                                <MenuItem onClick={() => handleSort('addedDesc')} fontWeight={sortOrder === 'addedDesc' ? 'bold' : 'normal'}>Date Added (oldest)</MenuItem>
                            </MenuList>
                        </Menu>
                    </HStack>
                    
                    <Center flex={"1"} >
                        {/* Edit Modal */}
                        <Modal isOpen={isEditModalOpen} onClose={closeEditModal} isCentered>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Edit Reminder
                                    <ModalCloseButton />
                                </ModalHeader>
                                <ModalBody>
                                    
                                    <Input 
                                        type={'text'} 
                                        name={'new_reminder_action'} 
                                        value={editReminderAction}
                                        onChange={(e) => setEditReminderAction(e.target.value)}
                                    />
                                    {/* TODO: This renders the date/time picker in the browser's local time.  May need to fix this in the future */}
                                    <Input 
                                        type={'datetime-local'} 
                                        min={nowDateTimeString}
                                        value={editFireDate}
                                        onChange={(e) => setEditFireDate(e.target.value)}
                                    />
                                </ModalBody>
                                <ModalFooter>
                                    <Wrap>
                                        <Button 
                                            bg={'brand.green'}
                                            // onClick={handleEditSubmit}
                                            _hover={{
                                                bg: 'brand.green_hover', // darken the button on hover
                                                // transform: 'scale(1.05)', // scale up the button on hover
                                                boxShadow: 'xl', // even larger shadow on hover
                                            }}   
                                        >
                                            Submit
                                        </Button>
                                    </Wrap>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                        {/* Flag Modal */}
                        <Modal isOpen={isFlagModalOpen} onClose={handleFlagClose} isCentered>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Did we get this reminder wrong?
                                    <ModalCloseButton />
                                </ModalHeader>
                                <ModalBody>
                                    <Text pb={4}>Please describe what you expected to happen with this reminder. We will review it and try to tweak the algorithm.</Text>
                                    <Textarea
                                        placeholder="Describe the issue..."
                                        minH="unset" // Remove the minimum height
                                        overflow="hidden" // Prevent scrollbar appearance
                                        resize="none" // Prevent manual resizing
                                        // minRows={2} // Start with two rows
                                        // Optional: If you want the Textarea to grow automatically
                                        onChange={(e) => {
                                            e.target.style.height = 'unset'; // Reset the height
                                            e.target.style.height = `${e.target.scrollHeight}px`; // Set the height based on scroll height
                                            setFeedbackText(e.target.value);
                                        }}
                                    />
                                </ModalBody>
                                <ModalFooter>
                                    <Wrap>
                                        <Button 
                                            isLoading={feedbackLoading}
                                            loadingText="Submitting"
                                            bg={'brand.green'}
                                            onClick={handleFlagSubmit}
                                            _hover={{
                                                bg: 'brand.green_hover', // darken the button on hover
                                                // transform: 'scale(1.05)', // scale up the button on hover
                                                boxShadow: 'xl', // even larger shadow on hover
                                            }}   
                                        >
                                            Submit
                                        </Button>
                                    </Wrap>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                        
                        <Box w={'100%'} 
                            // maxH={'275px'} 
                            // maxH={{base: "220", sm: "220", md: "250", lg: "270", xl: "320", "2xl": "320"}}
                            // flex={"1"} 
                            overflowY="auto"
                            position={"relative"}
                            overflowX={"hidden"}
                            ref={listRef}
                            pt={8}
                        >
                            <Accordion
                                allowMultiple
                                border={"0px"}
                            >
                                {reminderList.map(reminder => buildReminder(reminder))}
                            </Accordion>
                        </Box>
                        {isOverflowed && (
                            <Box
                                position="absolute"
                                bottom="0"
                                left="0"
                                right="0"
                                height="40px"
                                bgGradient="linear(to-b, transparent, rgba(217, 227, 243, 0.5)"
                            />
                        )}
                    </Center>
                </VStack>
                </Flex>
            </Center>
        </>
    );


}

export default Reminders;