import {
    Box,
    Button, Center,
    Checkbox,
    Container,
    FormControl,
    FormLabel,
    Heading, HStack,
    Input,
    Text,
    useToast
} from "@chakra-ui/react";
import React, {useContext, useEffect, useState} from "react";
import {useSessionContext} from "supertokens-auth-react/recipe/session";
import {UserContext} from "../UserContext";
import {Logtail} from "@logtail/browser";


function UserInputForm(props) {
    let session = useSessionContext();
    const user = useContext(UserContext)
    let {doesSessionExist, userId, accessTokenPayload} = session;
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(false)
    let page = props.page
    let setPage = props.setPage
    let onboarding = props.onboarding
    let names = props.name.split(" ")
    const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN)

    // console.log(names)
    // let firstName = names[0]
    // let [lastName, setLastName] = useState("")
    let [newUserFormState, setNewUserFormState] = useState({first_name: "",
                                                                                last_name: ""})

    useEffect(() => {
        // console.log("onboarding is " +onboarding)
        // console.log(user)
        if (onboarding) {
            // console.log("in onboarding useeffect")
            // console.log(names[0])
            // setNewUserFormState({...newUserFormState, first_name: names[0]})
            // setNewUserFormState({...newUserFormState, agree_to_terms: user.user_details.agreed_to_terms})
            if (names.length > 1) {
                let tempLastName = ""
                for (let i = 1; i < names.length; i++) {
                    tempLastName += names[i] + " "
                }
                // setLastName(tempLastName)
                setNewUserFormState({...newUserFormState, last_name: tempLastName, first_name: names[0]})
            }
        }
        else {
            setNewUserFormState({...newUserFormState, first_name: user.user_details.first_name,
                last_name: user.user_details.last_name})
        }
    }, [user])
    // console.log(newUserFormState)

    // function handleToggle(e) {
    //     if(newUserFormState.agree_to_terms === true){
    //         setNewUserFormState({...newUserFormState, agree_to_terms: false})
    //     } else {
    //         setNewUserFormState({...newUserFormState, agree_to_terms: true})
    //     }
    // }

    let handleSubmitND = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        let obj = {
            supertokens_id: userId,
            first_name: newUserFormState.first_name,
            last_name: newUserFormState.last_name,
            phone_number: newUserFormState.phone_number,
            // agree_to_terms: newUserFormState.agree_to_terms
        }
        if (newUserFormState.first_name == null) {
            obj.first_name = ""
        }
        if (newUserFormState.last_name == null) {
            obj.last_name = ""
        }
        if (newUserFormState.phone_number == null) {
            obj.phone_number = ""
        }
        // if (newUserFormState.agree_to_terms == null || newUserFormState.agree_to_terms === false) {
        //     toast({
        //         title: "Please agree to the terms and conditions",
        //         status: "error",
        //         duration: 9000,
        //         isClosable: true,
        //     })
        //     setIsLoading(false)
        //     return
        // }
        // console.log(obj)
        try {
            // console.log(obj)
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/add-new-user-info", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
            if (res.status === 200) {
                toast({
                    title: "Data added successfully",
                    status: "success",
                    isClosable: true
                })
                setIsLoading(false)
                user.setNeedsRefresh(true)
                if (onboarding) {
                    setPage(page + 1)
                }

            } else {
                logtail.error(user.user_details.email + ": error adding new user info in /add-new-user-info", res)
                logtail.flush()
                toast({
                    title: "Error adding data",
                    status: "error",
                    isClosable: true
                })
                setIsLoading(false)
            }
        } catch (err){
            console.log(err)
            logtail.error(user.user_details.email + ": error adding new user info in /add-new-user-info", err)
            logtail.flush()
            toast({
                title: "Error adding data",
                status: "error",
                isClosable: true
            })
            setIsLoading(false)
        }
    }

    if (onboarding) {
        return (
            <Box w={'100%'} pl={4} pr={4} sx={{
                boxShadow: '0 0 10px rgba(0,0,0,0.5)',
            }}>
                <Center>
                    <Heading as={'h1'} fontSize={'4xl'}>Welcome, {newUserFormState.first_name}!</Heading>
                </Center>
                <Center pt={2} pb={2}>
                    <Text>
                        We'd love to know more about you!
                    </Text>
                </Center>
                <form onSubmit={handleSubmitND}>
                    <FormControl>
                        <FormLabel>First Name</FormLabel>
                        <Input type={'text'} name={'first_name'}
                               value={newUserFormState.first_name}
                               onChange={e => setNewUserFormState({...newUserFormState, first_name: e.target.value})}/>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Last Name</FormLabel>
                        <Input type={'text'} name={'last_name'}
                               value={newUserFormState.last_name}
                               onChange={e => setNewUserFormState({...newUserFormState, last_name: e.target.value})}/>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Phone Number</FormLabel>
                        <Input disabled type={'text'} name={'phone_number'} onChange={e => setNewUserFormState({
                            ...newUserFormState,
                            phone_number: e.target.value
                        })}/>
                    </FormControl>
                    {/*<FormControl>*/}
                    {/*    <FormLabel>Agree To Terms<Text fontWeight={'bold'} as={'span'}*/}
                    {/*                                   color={'red'}>*</Text></FormLabel>*/}
                    {/*    /!*<Checkbox name={'agree_to_terms'} value={newUserFormState.agree_to_terms}*!/*/}
                    {/*    /!*          onChange={e => setNewUserFormState({*!/*/}
                    {/*    /!*    ...newUserFormState,*!/*/}
                    {/*    /!*    agree_to_terms: e.target.value*!/*/}
                    {/*    /!*})}/>*!/*/}
                    {/*    <Checkbox bg={'white'} name={'agree_to_terms'} isChecked={newUserFormState.agree_to_terms}*/}
                    {/*          onChange={handleToggle}/>*/}
                    {/*</FormControl>*/}
                    <HStack justify={'right'} pt={2} pb={2}>
                        <Button bg={'brand.green'} color={'gray.600'} isLoading={isLoading}
                                type={'submit'}>Next</Button>
                    </HStack>
                </form>
            </Box>
        )
    }
    else {
        return (
        <Box p={2}>
            <form onSubmit={handleSubmitND}>
                <FormControl>
                    <FormLabel>First Name</FormLabel>
                    <Input bg={'white'} type={'text'} name={'first_name'} value={newUserFormState.first_name}
                           onChange={e => setNewUserFormState({...newUserFormState, first_name: e.target.value})}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Input bg={'white'} type={'text'} name={'last_name'} value={newUserFormState.last_name}
                           onChange={e => setNewUserFormState({...newUserFormState, last_name: e.target.value})}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Phone Number</FormLabel>
                    <Input bg={'white'} disabled type={'text'} name={'phone_number'} onChange={e => setNewUserFormState({
                        ...newUserFormState,
                        phone_number: e.target.value
                    })}/>
                </FormControl>
                {/*<FormControl>*/}
                {/*    <FormLabel>Agree To Terms<Text fontWeight={'bold'} as={'span'}*/}
                {/*                                   color={'red'}>*</Text></FormLabel>*/}
                {/*    /!*<Checkbox bg={'white'} name={'agree_to_terms'} value={newUserFormState.agree_to_terms}*!/*/}
                {/*    /!*          onChange={e => setNewUserFormState({*!/*/}
                {/*    /!*    ...newUserFormState,*!/*/}
                {/*    /!*    agree_to_terms: e.target.value*!/*/}
                {/*    /!*})}/>*!/*/}
                {/*    <Checkbox bg={'white'} name={'agree_to_terms'} isChecked={newUserFormState.agree_to_terms}*/}
                {/*              onChange={handleToggle}/>*/}
                {/*</FormControl>*/}
                <HStack justify={'right'} pt={2} pb={2}>
                    <Button bg={'brand.pink'} color={'gray.600'} isLoading={isLoading}
                            type={'submit'}>Submit</Button>
                </HStack>
            </form>
        </Box>
        )
    }
}

export default UserInputForm;