import React, {useContext, useEffect} from 'react';
import { Heading, Text, Link, Flex, VStack } from '@chakra-ui/react';
import { Logtail } from "@logtail/browser";
import {UserContext} from "../UserContext";
import TopBar from './TopBar';


function Error(){
    const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN)
    const user = useContext(UserContext);
    let user_details = user.user_details

    useEffect(() => {
        logtail.info(user_details.email + ": loaded error page", {user_details: user_details})
        logtail.flush()
    }, [user_details]);

    return (
        <VStack>
            <TopBar />
            <Flex 
                w={"80%"} 
                h={"calc(100vh - 256px)"}
                justify={'center'} 
            >
                <Heading size={'md'} pt={4}>
                    Oops!  Looks like we got it wrong - sorry about that!
                </Heading>
            </Flex>
        </VStack>
    )
}

export default Error;