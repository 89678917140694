// R∞

import logo from './logo.svg';
//import './App.css';
import './style.css'
import HomeDesktop from './pages/home_desktop'
import HomeMobile from './pages/home_mobile';
import About from './pages/About'
import Contact from './pages/Contact'
import Error from './pages/Error'
import Settings from './pages/Settings'
import WithSubnavigation from "./pages/NavBar";
import LoggedOut from './pages/LoggedOut'
import React, {useContext} from 'react';
import {
  BrowserRouter,
  NavLink,
  Routes,
  Route,
  redirect
  // Link
} from "react-router-dom";
//import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { ThirdPartyEmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui";
import { EmailVerificationPreBuiltUI } from "supertokens-auth-react/recipe/emailverification/prebuiltui";
import EmailVerification from "supertokens-auth-react/recipe/emailverification";
import * as reactRouterDom from "react-router-dom";
// import Passwordless, { signOut } from "supertokens-auth-react/recipe/passwordless";
// import ThirdPartyPasswordless, { signOut } from "supertokens-auth-react/recipe/thirdpartypasswordless";
// import ThirdPartyEmailPassword, {Github, Google, Facebook, Apple, signOut} from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import ThirdPartyEmailPassword, {Github, Google, Facebook, Apple, signOut} from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Session from "supertokens-auth-react/recipe/session";
import { SessionAuth, useSessionContext } from "supertokens-auth-react/recipe/session";
// import {Github, Google, Facebook, Apple} from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import { useState, useEffect } from 'react';
import {
    extendTheme,
    ChakraProvider,
    createMultiStyleConfigHelpers,
    defineStyle,
    VStack,
    Flex,
    Spacer
} from "@chakra-ui/react";
import '@fontsource/exo-2/700.css';
// import '@fontsource/alegreya-sans/400.css'
// import '@fontsource/varela-round/400.css'
import { Heading, Center, Link } from "@chakra-ui/react"
import {theme} from "@chakra-ui/react";
import { Global } from "@emotion/react";
import Reminders from "./pages/Reminders";
import Faq from "./pages/faq";
import Pricing from "./pages/Pricing";
import NewUserFlow from "./pages/NewUserFlow";
// import AdminDashboard from "./pages/AdminDashboard";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import {UserContext, UserContextProvider} from "./UserContext";
import Connection from "./pages/Connection";
import CTABar from "./pages/CTABar";
import Footer from "./pages/Footer";
import PayPage from "./pages/PayPage";
import { ContactSupportOutlined } from '@mui/icons-material';
import Help from './pages/Help';
import DeleteMe from './pages/DeleteMe';
import RemindersForOthers from './pages/RemindersForOthers';
import Notifications from './pages/Notifications';
import Welcome from './pages/Welcome';


SuperTokens.init({
    // enableDebugLogs: true,
    appInfo: {
        appName: "ReminderLoop",
        apiDomain: process.env.REACT_APP_API_DOMAIN,
        websiteDomain: process.env.REACT_APP_WEBSITE_DOMAIN,
        apiBasePath: "/auth",
        websiteBasePath: "/auth"
    },
    // https://supertokens.com/docs/thirdpartyemailpassword/custom-ui/thirdparty-login#1714504619583
    clientType: "web-and-android",
    recipeList: [
        ThirdPartyEmailPassword.init({
            useShadowDom: false,
            signInAndUpFeature: {
                providers: [
                    Google.init(),
                    Apple.init(),
                ],
                signUpForm: {
                    formFields: [{
                        id: "firstName",
                        label: "First Name",
                        placeholder: "First Name",
                        optional: true
                    }, {
                        id: "lastName",
                        label: "Last Name",
                        placeholder: "Last Name",
                        optional: true
                    }],
                }
            },
            onHandleEvent: async (context) => {
                if (context.action === "SESSION_ALREADY_EXISTS") {
                    //
                } else if (context.action === "PASSWORDLESS_RESTART_FLOW") {
                    //
                } else if (context.action === "PASSWORDLESS_CODE_SENT") {
                    //
                } else if (context.action === "SUCCESS") {
                    if (context.isNewUser) {
                        if ("phoneNumber" in context.user) {
                            const { phoneNumber } = context.user;
                        } else {
                            const { email } = context.user;
                        }
                        //
                        let redir = true
                    } else {
                        //
                    }
                }
            },
            getRedirectionURL: async (context) => {
                // console.log("inside getRedirectionURL. context is: " + JSON.stringify(context))
                if (context.action === "SUCCESS") {
                    // https://supertokens.com/docs/thirdpartyemailpassword/advanced-customizations/frontend-hooks/redirection-callback
                    if (context.redirectToPath !== undefined) {
                        // console.log("undefined redirecting to path: " + context.redirectToPath)
                        // we are navigating back to where the user was before they authenticated
                        return context.redirectToPath;
                    }
                    if (context.isNewPrimaryUser) {
                        // console.log("new primary user")
                        // user signed up
                        // console.log("new user")
                        return "/signup";
                    } else if (context.isNewRecipeUser) {
                        return "/signup";
                    } else {
                        // console.log("existing user")
                        // user signed in
                    }
                    return "/";
                }
                return undefined;
            }

        }),
        EmailVerification.init({
            mode: "REQUIRED"
        }),
        Session.init()
    ]
});

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
  
    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
  
      window.addEventListener('resize', handleResize);
  
      // Call handler right away so state gets updated with initial window size
      handleResize();
  
      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect runs only on mount and unmount
  
    return windowSize;
  };


const reminderLoopTheme = extendTheme({
    fonts: {
        heading: `'Exo 2', sans-serif`,
        body: `'Alegreya Sans', sans-serif`,
    },
    textStyles: {
      logo: {
          fontFamily: `'Varela Round', sans-serif`,
      }
    },
    colors: {
        brand: {
            reminder_logo: "#736EB2",
            lp_logo: "#F0C28B",
            o_beginning_logo: "#a6d7cd",
            o_ending_logo: "#cda6cd",
            violet: "#8170F5",
            light_violet: "#D4A4E0",
            violet_hover: "#6D5FD0",
            violet_text: "#5A4DB2",
            pink: "#D4A4E0",
            pink_hover: "#C190D1",
            orange: "#EFC28B",
            orange_hover: "#D6A760",
            green: "#9DE8D4",
            green_hover: "#7db9a9",
            yellow: "#FDE376",
            yellow_hover: "#E3CC6A",
            black: "#2B272E",
            red: "#fc8181",
            red_hover: "#f56565",
            light_brown: "#EFC28B",

            rainbow_red: "#E57373",
            rainbow_orange: "#F0A64A",
            rainbow_yellow: "#FFEB3B",
            rainbow_green: "#66BB6A",
            rainbow_blue: "#42A5F5",
            rainbow_indigo: "#8170F5",
            rainbow_violet: "#AB47BC",
            rainbow_pink: "#F06292",
        }
    },
    components: {
        Progress: {
            baseStyle: (props) => ({
                // track: {
                //     bg: "brand.green",
                // },
                filledTrack: {
                    bg: "brand.violet",
                }
            })
        },
    }
});


function App() {
    const { width } = useWindowSize();
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    let session = useSessionContext();
    let [user_channel_details, setUserChannelDetails] = useState({"email":
            {"channel_id": 0, "default": false, "params": '{"email": "boom@boom.com"}'}})
    let [user_details, setUserDetails] = useState({"email": "", "reminders": [], "channels": [],
        "needsOnboarding": false, });
    let [userIsLoaded, setUserIsLoaded] = useState(false);
    let [channels, setChannels] = useState([{"service_name":"taco",
                                                                            "details": "burrito"}])
    let [channelsLoaded, setChannelsLoaded] = useState(false);
    let [needsRefresh, setNeedsRefresh] = useState(false);
    let [needsNavBar, setNeedsNavBar] = useState(true);

    async function onLogout() {
        await signOut();
        setIsLoggedIn(false)
        window.location.href="/";
    }

    // Determine the device type based on width
    let deviceType;
    // if (width < 760) {
    //     deviceType = 'mobile';
    // } else if (width >= 760 && width < 1024) {
    //     deviceType = 'tablet';
    // } else {
    //     deviceType = 'desktop';
    // }
    if (width < 1024) {
        deviceType = 'mobile';
    } else {
        deviceType = 'desktop';
    }

    return (
            <ChakraProvider theme={reminderLoopTheme}>
                <UserContextProvider>
                    <BrowserRouter>

                            <div>
                                    <Routes>
                                        {/*This renders the login UI on the /auth route*/}
                                        {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [ThirdPartyEmailPasswordPreBuiltUI, EmailVerificationPreBuiltUI])}
                                        {/*{getSuperTokensRoutesForReactRouterDom(require("react-router-dom"), [PasswordlessPreBuiltUI])}*/}
                                        {/*Your app routes*/}
                                        <Route path="/" element={<>
                                            {/*<LoggedOut/>*/}
                                            {/*{user_details.needsOnboarding ? <NewUserFlow setNeedsNavBar={setNeedsNavBar} channels={channels} /> : <Home/>}*/}
                                            {deviceType === 'mobile' && <HomeMobile />}
                                            {deviceType === 'tablet' && <p>tablet</p>}
                                            {deviceType === 'desktop' && <HomeDesktop />}
                                            
                                        </>} />
                                        <Route path="deleteme" element={
                                            <SessionAuth>
                                                <DeleteMe/>
                                            </SessionAuth>
                                        } />
                                        <Route path="help" element={<Help/>} />
                                        <Route path="remindersforothers" element={
                                            <SessionAuth>
                                                <RemindersForOthers/>
                                            </SessionAuth>
                                        } />
                                        <Route path="welcome" element={
                                            <SessionAuth>
                                                <Welcome/>
                                            </SessionAuth>
                                        } />
                                        <Route path="notifications" element={
                                            <SessionAuth>
                                                <Notifications/>
                                            </SessionAuth>
                                        } />
                                        <Route path="about" element={<About/>} />
                                        {/* <Route path="faq" element={<Faq/>} /> */}
                                        {/* <Route path="pricing" element={<Pricing/>} /> */}
                                        <Route path="reminders" element={
                                            <SessionAuth>
                                                <Reminders/>
                                            </SessionAuth>
                                        } />
                                        <Route path="settings" element={
                                            <SessionAuth>
                                                <Settings user_details={user_details} setChannelsLoaded={setChannelsLoaded}
                                                          userIsLoaded={userIsLoaded}
                                                          setUserIsLoaded={setUserIsLoaded} channels={channels}
                                                          setChannels={setChannels} user_channel_details={user_channel_details}
                                                          setUserChannelDetails={setUserChannelDetails} setNeedsRefresh={setNeedsRefresh}/>
                                            </SessionAuth>
                                        } />
                                        <Route path="onboarding/:checkout_session_id" element={
                                            <SessionAuth>
                                                <NewUserFlow setNeedsNavBar={setNeedsNavBar} channels={channels} />
                                            </SessionAuth>} />
                                        <Route path="/cx/:cx_id/:action?" element={<Connection/>} />
                                        <Route path="signup" element={
                                            <SessionAuth>
                                                <PayPage/>
                                            </SessionAuth>} />

                                        <Route path="terms" element={<Terms/>} />
                                        <Route path="privacy" element={<Privacy/>} />
                                        <Route path="*" element={<Error/>} />

                                    </Routes>
                            </div>
                                {/*</Center>*/}
                            <footer className={'footer--pin'}>
                                <Footer />
                            </footer>
                                {/*</Center>*/}

                    </BrowserRouter>
                </UserContextProvider>
            </ChakraProvider>
    );

}

export default App;
