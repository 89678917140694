import React, {useEffect, useReducer, useState, useContext} from 'react';
import {
    Box,
    Center,
    Text,
    VStack,
    OrderedList,
    ListItem,
    RadioGroup,
    Radio,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Container,
    Input,
    FormControl,
    Button,
    TableContainer,
    Table,
    TableCaption,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    FormLabel,
    useToast,
    HStack,
    InputGroup,
    Checkbox,
    Stack,
    Fade,
    ScaleFade,
    Slide,
    SlideFade,
    Collapse,
    useDisclosure,
    ButtonGroup,
    PopoverTrigger,
    Popover,
    PopoverArrow,
    PopoverCloseButton, PopoverHeader, PopoverBody, PopoverContent, Flex, Link, Spacer,
} from "@chakra-ui/react"
import {useSessionContext} from "supertokens-auth-react/recipe/session";
import DisplayChannelText from "./DisplayChannelText";
import {useNavigate, useParams, useSearchParams } from "react-router-dom";
import SettingStack from "./SettingStack";
import {Field, Formik, useFormik} from "formik";
import UserInputForm from "./UserInputForm";
// import Stepper from "awesome-react-stepper";
import TxRxReminders from "./TxRxReminders";
import { UserContext } from "../UserContext";
import {InfoOutlineIcon} from "@chakra-ui/icons";
import { Logtail } from "@logtail/browser";
import PricingTable from "./PricingTable";

// /onboarding/:checkout_session_id
function NewUserFlow(props){
    const routeParams = useParams();
    // const [searchParams, setSearchParams] = useSearchParams();
    const user = useContext(UserContext)
    let setNeedsNavBar = props.setNeedsNavBar
    setNeedsNavBar(false)
    // let user_details = user.user_details
    let channels = props.channels
    // let user_channel_details = user.user_channel_details
    // let [output, setOutput] = useState([])
    // let [emailIsChannel, setEmailIsChannel] = useState(false)
    // let [phoneIsChannel, setPhoneIsChannel] = useState(false)
    // let [editMode, setEditMode] = useState(false)
    // let [formState, setFormState] = useState({});
    // let setNeedsRefresh = user.setNeedsRefresh
    let session = useSessionContext();
    // let {doesSessionExist, userId, accessTokenPayload} = session;
    // const [defaultChannel, setDefaultChannel] = useState('email')
    // const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    // const toast = useToast()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    // let [newUserFormState, setNewUserFormState] = useState({})
    // const {isOpen, onToggle} = useDisclosure()
    const [page, setPage] = useState(0)
    const [isOnboarding, setIsOnboarding] = useState(true)
    const [checkoutData, setCheckoutData] = useState({})
    const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN)

    useEffect(() => {
        // if (searchParams)
        // console.log(searchParams.get("a"))
        const url = process.env.REACT_APP_API_DOMAIN + "/checkout/" + routeParams.checkout_session_id
        const options = {
            method: "GET"
        }
        try {
            fetch(url, options)
                .then(response => response.json())
                .then(data => {
                    setCheckoutData(data)
                    // console.log(data.checkout_session.customer_details.name)
                    // console.log(data)
                    setLoading(false)
                })
        } catch (err) {
            logtail.error(user.user_details.email + ": error in GET /checkout/", err)
            logtail.flush()
            console.log(err)
            setLoading(false)
        }
    }, [])

    // if (user_details.is_active) {
    //     return <Navigate to="/settings" />
    // }

    if (loading) {
        return (
            <Center pt={3}>
                <Text>Loading...</Text>
            </Center>
        )
    }
    else {
        return (
            <Center>
                {/*{page === 0 && <PricingTable />}*/}
                {page === 0 && <UserInputForm page={page} setPage={setPage}
                                              name={checkoutData.checkout_session.customer_details.name}
                                              onboarding={true}/>}
                {page === 1 && <OnboardingSettings channels={channels} page={page} setPage={setPage}
                                                   isOnboarding={isOnboarding}/>}
                {page === 2 && <TxRxReminders setNeedsNavBar={setNeedsNavBar} page={page} setPage={setPage}/>}
            </Center>
        )
    }
}

function OnboardingSettings(props){
    const user = useContext(UserContext)
    let channels = props.channels
    let user_channel_details = user.user_channel_details
    let setNeedsRefresh = user.setNeedsRefresh
    let page = props.page
    let setPage = props.setPage
    let isOnboarding= props.isOnboarding

    return(
        <Box >
            <Box pr={2} pl={2} sx={{
                boxShadow: '0 0 10px rgba(0,0,0,0.5)',
            }}>
                <Box>
                    <Popover>
                        <Text fontSize={'lg'} as={'span'}>
                            To start, we will send your reminders to your email address.
                                That said, emails often drown in an inbox, so we recommend taking a moment to set up an additional
                                <PopoverTrigger><Link as={'span'}> channel <InfoOutlineIcon fontSize={'sm'}/></Link></PopoverTrigger> as a default:
                        </Text>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader>Channels</PopoverHeader>
                            <PopoverBody>Channels are the ways that you receive reminders. The default way to receive a reminder is through the email that you signed up with. You can add more channels by clicking on the channel title and inputting the information for that channel. Each channel offers a button you can click for additional information on how to set it up.
                                Set your default channel with the bottons on the left side of the screen.
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                </Box>
                <SettingStack channels={channels} user_channel_details={user_channel_details}
                                  setNeedsRefresh={setNeedsRefresh} page={page} setPage={setPage}
                                    isOnboarding={isOnboarding}/>
                <Text fontSize={'lg'} pt={3} pb={3}>
                    When you're done adding channels, click Next.  You can always add more channels later
                        from your Account page.
                </Text>
        </Box>
            <Flex pb={2} pt={2}>
                <Button color={'gray.600'} bg={'red.300'} onClick={() => setPage(page-1)}>Back</Button>
                <Spacer />
                <Button color={'gray.600'} bg={'brand.green'} onClick={() => setPage(page+1)}>Next</Button>
            </Flex>
        </Box>
    )

}


export default NewUserFlow;