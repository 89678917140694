import React from 'react';
import { Box, Text, Link, Flex, VStack } from '@chakra-ui/react';
import TopBar from './TopBar';
import RemindersForOthersDisplay from './RemindersForOthersDisplay';

const RemindersForOthers = () => {
  return (
    <VStack>
        <TopBar />
        <Flex 
            w={"80%"} 
            minH={"calc(100vh - 256px)"}
            justify={'center'} 
        >
            <RemindersForOthersDisplay />
            
        </Flex>
    </VStack>
  );
};

export default RemindersForOthers;