import React, { useState } from 'react';
import { Box, Button, Flex, IconButton, Spacer,useColorModeValue, VStack, HStack, Text, Heading } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import TestimonialBox from './TestimonialBox';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const testimonials = [
    {
        name: "Andrew Byrley",
        role: "ReminderLoop Founder",
        testimonial: "ReminderLoop is a revolutionary technology. There is literally no better way to set reminders — I should know!",
        avatarImg: "./andrewbyrleytestimonial.jpg"
    },
    {
        name: "Oops E. Daisy",
        role: "Habitual Forgetter",
        testimonial: "Normally I'd forget my head if it wasn't attached. With ReminderLoop, I can finally remember ... I'm sorry, what are we doing here?",
        avatarImg: ""
    },
    {
        name: "helpme",
    },
    // {
    //     name: "Leslie Alexander",
    //     role: "Freelance React Developer",
    //     testimonial: "You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.",
    //     avatarImg: ""
    // }
]

const Testimonials = () => {
    var [isFeedbackButtonVisible, setIsFeedbackButtonVisible] = useState(false);
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
        accessibility: true,
        beforeChange: (current, next) => {
            // console.log("next slide index:", next);
            var numTestimonials = testimonials.length;
            // console.log("numTestimonials:", numTestimonials);
            if (next === numTestimonials - 1) {
                // console.log("showing feedback button");
                setIsFeedbackButtonVisible(true);
            } else {
                setIsFeedbackButtonVisible(false);
            }

            // setOldSlide(current);
            // setActiveSlide(next);
          },
    };

    return (
        <Flex direction="column" align="center" justify="center" pb={7}>
            <Box w={{base: "xs", sm: "xs", md: "xs", lg: "xs", xl: "sm", "2xl": "md"}}>
                <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        testimonial.name === "helpme" ? <HelpMeTestimonialBox key={index} isFeedbackButtonVisible={isFeedbackButtonVisible}/> : <TestimonialBox key={index} {...testimonial} /> 
                        // <TestimonialBox key={index} {...testimonial} />
                    ))}
                </Slider>
            </Box>
        </Flex>
    )

};

const HelpMeTestimonialBox = ({isFeedbackButtonVisible}) => {
    const bg = useColorModeValue('white', 'gray.800');

    return (
        <VStack
          spacing={4}
          bg={bg}
          boxShadow="lg"
          padding={4}
          borderRadius="lg"
          borderWidth="1px"
        //   borderColor={borderColor}
          // width={{lg: "xs", xl: "sm", "2xl": "md"}}
          align="center"
          h={"250px"}
        >
            <Heading pt={5} size={"md"} color={"blackAlpha.700"}>Tell us what you love!</Heading>
            {/* <Text>And help us shape our testimonials from a literal joke 😃</Text> */}
            <Spacer />
            <Button 
                bg={"brand.pink"}
                aria-hidden="true"
                isDisabled={!isFeedbackButtonVisible}
                onClick={() => window.open('https://senja.io/p/reminderloop/r/e8IkKD', '_blank')}
            >
                Click here to leave a review!
            </Button>
            <Spacer />
          
          
          
        </VStack>
      );
  };

export default Testimonials;
