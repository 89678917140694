import React, { useContext, useEffect, useState } from 'react';
import { 
    Box, 
    Text, 
    Flex, 
    VStack, 
    Heading,
    Center,
    Spinner,
    RadioGroup,
    Radio, 
    useToast,
    Tooltip,
} from '@chakra-ui/react';
import {
    GooglePlayButton,
    AppStoreButton,
    ButtonsContainer,
  } from "react-mobile-app-button";
import TopBar from './TopBar';
import { UserContext } from "../UserContext";

const Notifications = () => {
    const { user_details, loading } = useContext(UserContext);
    const [hasMobile, setHasMobile] = useState(false);

    // Assuming channels is an array, parse it directly if it's stored as a string
    const channels = Array.isArray(user_details.channels) ? user_details.channels : JSON.parse(user_details.channels);

    useEffect(() => {
        const hasFirebaseChannel = channels.some(channel => channel.service_name === 'firebase');
        setHasMobile(hasFirebaseChannel);
        // console.log("Has Firebase channel: " + hasFirebaseChannel);
    }, [user_details, channels]);

    return (
        <VStack>
            <TopBar />
            <Flex 
                w={"80%"} 
                minH={"calc(100vh - 256px)"}
                justify={'center'} 
            >
                <VStack>
                    <Heading pb={5}>Notification Settings</Heading>
                    {loading ? (
                        <Center h={"calc(100vh - 256px)"}>
                            <Spinner size="xl" />
                        </Center>
                    ) : (
                        <ChannelList channels={channels} />
                    )}
                    {!hasMobile && <AppButtons />}
                </VStack>
            </Flex>
        </VStack>
    );
};

const AppButtons = () => {
    const APKUrl = "https://play.google.com/apps/testing/com.reminderloop.reminderloop";
    // const IOSUrl = "https://apps.apple.com/us/app/";
    const buttonHeight = 50;
    const buttonWidth = 185;

    return (
        <VStack pt={10} spacing={0}>
            <Heading pb={4} size={'sm'}>Download the app to get ReminderLoop on your phone!</Heading>
            <Flex justifyContent={'center'}>
                <ButtonsContainer>
                    <GooglePlayButton
                        url={APKUrl}
                        theme={"dark"}
                        height={buttonHeight}
                        width={buttonWidth}
                        // className={"custom-button-style"}
                    />
                    <AppStoreButton
                        // url={IOSUrl}
                        theme={"dark"}
                        height={buttonHeight}
                        width={buttonWidth}
                        // className={"custom-button-style"}
                    />
                </ButtonsContainer>
            </Flex>
            <Flex w={'100%'} justify={'end'}>
                <Text fontStyle={'italic'} pr={"65px"}>Coming Soon!</Text>
            </Flex>
        </VStack>
    );

}

const ChannelList = ({ channels }) => {
    // Use `find` to get the default channel object, then get its `id`.
    // If no default channel is found, fallback to `undefined` or the first channel's id.
    const defaultChannelId = channels.find(channel => channel.default === true)?.id || channels[0]?.id;
    const toast = useToast();
    const { user_details, setNeedsRefresh } = useContext(UserContext);

    // No need for useState or useEffect here, as the defaultChannelId will
    // be recalculated whenever the channels prop changes, which will trigger a re-render.

    let handleDefaultChange = async (e) => {
        // setDefaultChannel(e)
        console.log('default channel is now ' + e)
    //     console.log("superTokensId: " + userId)
    //     console.log("channelId: " + user_channel_details[e].channel_id)
        let obj = {}
        obj.supertokens_id = user_details.supertokens_id
        obj.channel_id = e.toString()
        try{
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/set-default-channel", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
            if (res.status ===200) {
                // console.log("default channel updated successfully");
                // console.log(e)
                toast({
                    title: "You changed how you recieve your reminders!",
                    status: "success",
                    isClosable: true
                })
            } else {
                console.log("some error occurred");
                // logtail.error(user_details.email + ": some error occurred while setting default channel")
                // logtail.flush()
                toast({
                    title: "some error occurred",
                    status: "error",
                    isClosable: true
                })
            }
        } catch (err) {
            // logtail.error(user_details.email + ": some error occurred while setting default channel", err)
            // logtail.flush()
            console.log(err);
        }

        setNeedsRefresh(true)
    }

    return (
        <VStack>
            <Heading size="md" pb={5}>How would you like to recieve your reminder notifications?</Heading>
            <RadioGroup defaultValue={defaultChannelId?.toString()} onChange={handleDefaultChange}>
                <Flex direction={'column'} w={'400px'}>
                {channels.map(channel => {
                    let displayElement;
                    switch (channel.service_name) {
                        case 'email':
                            displayElement = (
                                <Box>
                                    <Text fontSize={'lg'}>{"Email (" + user_details.email + ")"}</Text>   
                                </Box>
                            );
                            break;
                        case 'firebase':
                            const params = JSON.parse(channel.params);
                            const deviceModel = params.device_model;
                            displayElement = (
                                <Box>
                                    <Text fontSize={'lg'}>{deviceModel}</Text>
                                </Box>
                            );
                            break;
                        // case 'ZZ':
                        //     displayElement = (
                        //         <Box color="green">
                        //             <Text>{channel.service_name}</Text>
                        //         </Box>
                        //     );
                        //     break;
                        default:
                            displayElement = <Text>{channel.service_name}</Text>;
                    }
                    return (
                        <Radio pb={2} key={channel.id} value={channel.id.toString()}>
                            {displayElement}
                        </Radio>
                    );
                })}
                </Flex>
            </RadioGroup>
        </VStack>
    );
};

export default Notifications;
