import React from 'react';
import {Box, Center, Text, VStack, OrderedList, ListItem} from "@chakra-ui/react"

function About(){
    return (
        <Center>
            <VStack spacing={'10px'}>
                <Text as={'h1'} fontSize={'4xl'}>What is ReminderLoop?</Text>
                <OrderedList>
                    <ListItem>Sign Up</ListItem>
                    <ListItem>Add a channel to receive your reminders</ListItem>
                    <ListItem>Tell us your reminder!</ListItem>
                </OrderedList>
            </VStack>
    </Center>
  )
}

export default About;