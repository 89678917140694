import {
    Box,
    Button,
    ButtonGroup,
    Center,
    Checkbox,
    Container,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Spacer,
    Text,
    useToast
} from "@chakra-ui/react";
import React, {useEffect, useState, useContext} from "react";
import {useSessionContext} from "supertokens-auth-react/recipe/session";
import {useNavigate} from "react-router-dom";
import {InfoOutlineIcon} from "@chakra-ui/icons";
import {UserContext} from "../UserContext";
import { Logtail } from "@logtail/browser";


function TxRxReminders(props) {
    let [newUserFormState, setNewUserFormState] = useState({nickname: []})
    let [newUserEmail, setNewUserEmail] = useState("")
    let session = useSessionContext();
    let {doesSessionExist, userId, accessTokenPayload} = session;
    const toast = useToast()
    const navigate = useNavigate()
    let page = props.page
    let setPage = props.setPage
    let setNeedsNavBar = props.setNeedsNavBar
    let [isLoading, setIsLoading] = useState(false)
    let user = useContext(UserContext)
    const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN)

    let handleSubmitTR = async (e) => {
        e.preventDefault()
        // console.log(newUserFormState.email)
        if (newUserEmail === "" || typeof newUserEmail === 'undefined') {
            toast({
                title: "Please enter an email",
                status: "error",
                isClosable: true
            })
            return
        }

        setIsLoading(true)
        let newArr = newUserFormState.nickname.filter(function(e){return e})
        setNewUserFormState({...newUserFormState, nickname: newArr})
        let obj = {
            supertokens_id: userId,
            email: newUserEmail,
            nickname: newUserFormState.nickname
        }
        // For some reason we have to do this twice.  I don't know.
        obj.nickname = obj.nickname.filter(function(e){return e})
        // console.log(obj)
        try {
            // console.log(obj)
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/add-to-loop", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
                .then(res => res.json())
                .then(resJSON => {
                    // if (res.status === 200) {
                        // let resJSON = res.json()
                        // console.log(resJSON)
                        if (resJSON.results === "no user found") {
                            toast({
                                title: "No user found with that email",
                                status: "info",
                                isClosable: true
                            })
                            setIsLoading(false)
                        } else if (resJSON.results === "not authorized") {
                            toast({
                                title: "You are not authorized to add this user",
                                status: "error",
                                isClosable: true
                            })
                            setIsLoading(false)
                        } else if (resJSON.results === "already connected") {
                            toast({
                                title: "You are already connected to this user",
                                status: "info",
                                isClosable: true
                            })
                            setIsLoading(false)
                        } else if (resJSON.results === "already requested") {
                            toast({
                                title: "You have a pending connection to this user",
                                status: "info",
                                isClosable: true
                            })
                            setIsLoading(false)
                        } else {
                            toast({
                                title: "Success!",
                                description: resJSON.results,
                                status: "success",
                                isClosable: true
                            })
                            setIsLoading(false)
                        }
                })

        } catch (err){
            console.log(err)
            logtail.error(user.user_details.email + ": error adding user to loop", err)
            logtail.flush()
            toast({
                title: "Error adding data",
                status: "error",
                isClosable: true
            })
            setIsLoading(false)
        }
    }

    let handleTempDone = async () => {
        setNeedsNavBar(true)
        user.setNeedsOnboarding(false)

        navigate('/')
        let obj = {
            is_onboarded: true
        }
        try {
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/update-onboarded", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
                .then(res => {
                    if (res.status === 200) {

                    } else {
                        logtail.error(user.user_details.email + ": error updating onboarded status in /update-onboarded", res)
                        logtail.flush()
                    }
                })
        } catch (err) {
            console.log(err)
            logtail.error(user.user_details.email + ": error updating onboarded status in /update-onboarded", err)
            logtail.flush()
        }
        user.setNeedsRefresh(true)
    }

    return(
        <Box>
            <Box sx={{
                boxShadow: '0 0 10px rgba(0,0,0,0.5)',
            }}>
                <Center>
                    <Heading as={'h1'} fontSize={'4xl'}>Add To Your Loop</Heading>
                </Center>
                <Center p={3}>
                    <Text fontSize={'xl'} pb={5}>People in your loop can send you reminders (and vice versa). If you don't want to add anyone to your loop right now, click Done.</Text>
                </Center>
                <Box p={3}>
                    <form onSubmit={handleSubmitTR}>
                        <FormControl>
                            <FormLabel>Email of person to add to your loop:</FormLabel>
                            {/*<Input type={'email'} name={'email'} onChange={(e) => setNewUserFormState({...newUserFormState, email: e.target.value})}/>*/}
                            <Input type={'email'} name={'email'} onChange={(e) => setNewUserEmail(e.target.value)}/>
                        </FormControl>
                        <NicknameInput newUserFormState={newUserFormState} setNewUserFormState={setNewUserFormState}/>
                        <HStack justify={'right'} pt={1}>
                            <Button color={'gray.600'} bg={'brand.pink'} isLoading={isLoading} type={'submit'}>Add to my loop</Button>
                        </HStack>
                    </form>
                </Box>
                <Center pt={3} pl={3} pr={3}>
                    <Text fontSize={'xl'} pb={5}>We'll reach out to your fellow looper to ask them to confirm your request and let you know what they say!</Text>
                </Center>
            </Box>

            <Flex  pt={3} pb={2} pl={3} pr={3}>
                <Button color={'gray.600'} bg={'red.300'} onClick={() => setPage(page-1)}>Back</Button>
                <Spacer />
                <Button color={'gray.600'} bg={'brand.green'} onClick={handleTempDone}>Done</Button>
            </Flex>
        </Box>
    )
}

function NicknameInput(props) {
    let [numNicknames, setNumNicknames] = useState(1)
    let [inputArray, setInputArray] = useState([])

    // console.log(props.newUserFormState)
    const updateNicknames = index => e => {
        // console.log('index is ' + index)
        // console.log('e.target.value is ' + e.target.value)
        // console.log(props.newUserFormState.nickname)
        // console.log(e.target.value.length)
        let newArr = props.newUserFormState.nickname
        // console.log("newArr is " + newArr)
        if (e.target.value.length >0) {
            if (newArr.length === numNicknames) {
                setNumNicknames(numNicknames + 1)
            }
        }
        // console.log(newArr)
        newArr[index] = e.target.value
        props.setNewUserFormState({...props.newUserFormState, nickname: newArr})
        // console.log("newUserFormState is ")
        // console.log(props.newUserFormState)
    }

    useEffect(() => {
        // console.log(props.newUserFormState)
        if (props.editMode) {
            setNumNicknames(props.newUserFormState.nickname.length + 1)
        }
    }, [props.newUserFormState])

    useEffect(() => {
        let arr = []
        for (let i = 0; i < numNicknames; i++) {
            arr.push(
                <FormControl>
                    {/*<Input key={'nickname' + i} type={'text'} name={'nickname' + i} onChange={(e) => props.setNewUserFormState({...props.newUserFormState, nickname[i]: e.target.value})}/>*/}
                    <Input key={'nickname' + i} type={'text'} name={'nickname' + i} onChange={updateNicknames(i)} placeholder={'Nickname'} value={props.newUserFormState.nickname[i]}/>
                </FormControl>
            )
        }
        setInputArray(arr)
    }, [numNicknames, props.newUserFormState])


    return(
        // <FormControl>
        <Box pt={3}>
            <Popover>
                <HStack>
                    <FormLabel m={0}>Nickname(s): </FormLabel>
                    <PopoverTrigger><Flex ><InfoOutlineIcon color={'black'}/></Flex></PopoverTrigger>
                </HStack>
                <PopoverContent>
                    <PopoverArrow />
                    {/*<PopoverCloseButton />*/}
                    {/*<PopoverHeader>Nicknames</PopoverHeader>*/}
                    <PopoverBody>
                        <Text fontSize={'sm'} pb={1}>
                            Nicknames are the way you refer to your other people in your reminders. If you don't give us a nickname, you'll need to provide the email address of the person you want to send a reminder to.
                        </Text>
                        <Text as={'i'} fontWeight={'bold'}>
                            Remind John to ...
                        </Text>
                        <Text>
                            vs.
                        </Text>
                        <Text as={'i'} fontWeight={'bold'}>
                            Remind john.smith@gmail.com to ...
                        </Text>
                    </PopoverBody>
                </PopoverContent>
                {inputArray}
            </Popover>
        </Box>
        // </FormControl>
    )


}

export default TxRxReminders;
export {NicknameInput};