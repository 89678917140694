import React from 'react';
import {useState} from "react";
import {useEffect, useContext } from "react";
import { Text, Box, Table, Thread, Tbody, Tfoot, Tr, Th, Td, TableContainer } from "@chakra-ui/react"
import { UserContext } from "../UserContext";

function DisplayChannelText(props){
    const user = useContext(UserContext);
    const channel_details = props.channel_details;
    const user_channel_details = user.user_channel_details;
    const [tokenArray, setTokenArray] = useState([]);
    const [output, setOutput] = useState([]);
    // console.log("in displaychanneltext")
    useEffect(() => {
        let tempArray = [];

        Object.keys(channel_details.details.tokens).map(function(key){
            // console.log(key)
            // tempArray.push(key);
            if (key === "schema") {
                // console.log("schema found")
                // for now we aren't giving 'schema' to the user
            } else {
                tempArray.push(key);
            }
        })

        setTokenArray(tempArray); // this doesn't work :(
        let buildArray = [];
        // console.log("temparray is "+tempArray)
        // console.log("formstate is "+ JSON.stringify(formState))
        // console.log("user_channel_details is "+ JSON.stringify(user_channel_details))
        for (let i = 0; i < tempArray.length; i++){ // Object.keys(channel_details.details.tokens).length
            buildArray.push(<DisplayTokenText key={channel_details.service_name+'_'+i} service_name={channel_details.service_name} default_values={user_channel_details} name={tempArray[i]} tokens={channel_details.details.tokens[tempArray[i]]} />)
        }
        setOutput(buildArray);
    }, [channel_details]);

    return (
        <TableContainer>
            <Table variant='striped' size={'sm'}>
                <Tbody>
            {/*<h3>{channel_details.service_name}</h3>*/}
                    {output}
                </Tbody>
            </Table>
        </TableContainer>
    )

}


function DisplayTokenText(props){
    const tokens = props.tokens;
    const name = props.name;
    // let formState = props.formState
    const default_values = props.default_values;
    const service_name = props.service_name;
    const [default_value_json, setDefaultValueJson] = useState({})

    useEffect(() => {
        if (default_values[service_name] !== undefined) {
            setDefaultValueJson(JSON.parse((default_values[service_name]["params"]).replace(/'/g, '"')))
        }
    }, [default_values, service_name])

    return (
        <Tr key={tokens.name}>
            <Td>{tokens.name}</Td>
            <Td>{default_value_json[name]}</Td>
        </Tr>
    )
}
export default DisplayChannelText