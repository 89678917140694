import React, { useState, useRef } from 'react';
import { useToast, Box, Text, Link, Flex, VStack, Button, Spinner, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import TopBar from './TopBar';
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Session from "supertokens-auth-react/recipe/session";
// import { useNavigate } from "react-router-dom";

const DeleteMe = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef();
  let sessionContext = Session.useSessionContext();
  let { userId } = sessionContext;
  const toast = useToast();
  // let navigate = useNavigate();

  const handleDeleteClick = () => {
    setIsOpen(true);
  };

  const deleteTheAccount = async () => {
    setIsLoading(true);
    try {
      let obj = {};
      obj.supertokens_id = userId;

      let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/delete-user", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      })
      if (res.status === 200) {
        toast({
          title: "Your account has been deleted.",
          // description: resJSON.results,
          status: "success",
          isClosable: true
        })
        await signOut();
        // navigate("/");
        window.location.href = "/";
      } else {
        toast({
          title: "We experienced an error deleting your account.",
          description: "Please contact help@reminderloop.com",
          status: "error",
          isClosable: true
        })
      }
      
      // Handle successful deletion (e.g., redirect to another page or show a success message)
    } catch (error) {
      // Handle error (e.g., show an error message)
      console.error('Failed to delete account:', error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <VStack>
        <TopBar />
        <Flex 
            w={"80%"} 
            h={"calc(100vh - 256px)"}
            justify={'center'} 
        >
            <Box pt={10}>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    DELETE YOUR ACCOUNT:
                </Text>
                <Text>
                    To delete your account, please click the big red button below.  We will miss you, but we understand that sometimes it's time to move on.
                </Text>
                <Text mt={4}>
                    Please note that this action is permanent and cannot be undone.  If you have any questions or concerns, please send an email to{' '}
                    <Link href="mailto:help@reminderloop.com" color="blue.500">
                        help@reminderloop.com
                    </Link>
                    . We recommend that you cancel your subsctiption before deleting your account to avoid any future charges.
                </Text>
                <Button colorScheme="red" mt={4} onClick={handleDeleteClick}>
                    Delete My Account
                </Button>
                
                
            </Box>
            
        </Flex>
        <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Account
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteTheAccount} ml={3} isLoading={isLoading}>
                {isLoading ? <Spinner size="sm" /> : 'Delete My Account'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  );
};

export default DeleteMe;
