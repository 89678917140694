import React from 'react';
import { Box, Text, Link, Flex, VStack } from '@chakra-ui/react';
import TopBar from './TopBar';
import { Link as RouterLink } from "react-router-dom";

const Help = () => {
  
  return (
    <VStack>
        <TopBar />
        <Flex 
            w={"80%"} 
            h={"calc(100vh - 256px)"}
            justify={'center'} 
        >
            <Box pt={10}>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    How to Contact Us for Help:
                </Text>
                <Text>
                    If you need assistance, please send an email to{' '}
                    <Link href="mailto:help@reminderloop.com" color="blue" textDecoration="underline">
                        help@reminderloop.com
                    </Link>
                    . Our support team will get back to you as soon as possible.
                </Text>
                <Text mt={4}>
                    If you have any feedback for a specific reminder, you can click on the down arrow next to the reminder and select "Flag a Problem" and we'll look into it right away!
                </Text>
                <Text mt={20}>
                    If you would like to delete your account, please {' '}
                    <RouterLink to="/deleteme" style={{ color: 'blue', textDecoration: 'underline' }}>
                        click here
                    </RouterLink>
                    .

                </Text>
                
            </Box>
            
        </Flex>
    </VStack>
  );
};

export default Help;
