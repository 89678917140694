import React, { useState, useEffect, useContext, useRef } from 'react';
// import { DeleteIcon, EditIcon, CloseIcon, ViewOffIcon, CheckCircleIcon, MinusIcon } from "@chakra-ui/icons";
import {
    useToast,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Center,
    Spacer,
    Text,
    HStack,
    Flex,
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    VStack,
    Textarea,
    Tooltip,
    Button,
    Input, ButtonGroup, Wrap, Grid, GridItem, Spinner, Avatar, Icon, useBreakpointValue, Select, Menu, MenuButton, MenuList, MenuItem, IconButton
} from "@chakra-ui/react"
// import { createColumnHelper} from "@tanstack/react-table";
import {UserContext} from "../UserContext";
// import EditReminderModal from "./EditReminderModal";
// import { Logtail } from "@logtail/browser";
// import { ContactSupportOutlined, Grid3x3 } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import SortIcon from '@mui/icons-material/Sort';
import SnoozeIcon from '@mui/icons-material/Snooze';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { ContactSupportOutlined } from '@mui/icons-material';

const options = ["From", "Self", "To"];

const SegmentedControl = ({ onSelectionChange, selectedOptions, setSelectedOptions }) => {
  

  const toggleOption = (option) => {
    const currentIndex = selectedOptions.indexOf(option);
    const newSelectedOptions = [...selectedOptions];

    if (currentIndex === -1) {
      newSelectedOptions.push(option);
    } else {
      newSelectedOptions.splice(currentIndex, 1);
    }

    setSelectedOptions(newSelectedOptions);
    onSelectionChange(newSelectedOptions);
  };

  return (

    <ButtonGroup isAttached>
        {options.map((option, index) => (
            <Button
                key={option}
                w={{lg: "60px", xl: "65px", "2xl": "65px"}}
                h={{lg: "35px", xl: "40px", "2xl": "40px"}}
                bg={selectedOptions.includes(option) ? "brand.green" : "gray.300"}
                onClick={() => toggleOption(option)}
                borderRadius={index === 0 ? 'full' : index === options.length - 1 ? 'full' : '0'}
                // Apply a left radius to the first item and a right radius to the last item
                {...(index === 0 && {borderTopLeftRadius: 'full', borderBottomLeftRadius: 'full'})}
                {...(index === options.length - 1 && {borderTopRightRadius: 'full', borderBottomRightRadius: 'full'})}
                // Apply a right border to all but the last item
                borderRight={index !== options.length - 1 ? '1px' : 'none'}
                borderColor="gray.200" // Use a border color that suits your design
            >
                <Text fontSize={{lg: "sm", xl: "md", "2xl": "md"}} fontWeight={'bold'} color={"blackAlpha.700"}>
                    {option}
                </Text>
            </Button>
        ))}
    </ButtonGroup>

  );
};

function LiveReminderList() {
    // const [isLoading, setIsLoading] = useState(true);
    const [isOverflowed, setIsOverflowed] = useState(false);
    const listRef = useRef(null);
    const user = useContext(UserContext);
    let user_details = user.user_details;
    let [reminderList, setReminderList] = useState([])
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isFlagModalOpen, setIsFlagModalOpen] = useState(false);
    const [isCustomSnoozeModalOpen, setIsCustomSnoozeModalOpen] = useState(false);
    // const [reminderUuidToEdit, setReminderUuidToEdit] = useState(null);
    const [reminderToEdit, setReminderToEdit] = useState(null);
    const [reminderToCustomSnooze, setReminderToCustomSnooze] = useState(null);
    const [reminderUuidToFlag, setReminderUuidToFlag] = useState(null);
    const [feedbackText, setFeedbackText] = useState('');
    const [feedbackLoading, setFeedbackLoading] = useState(false);
    const [editReminderAction, setEditReminderAction] = useState('');
    const [editFireDate, setEditFireDate] = useState('');
    const [editLoading, setEditLoading] = useState(false);
    const [customSnoozeLoading, setCustomSnoozeLoading] = useState(false);
    const [nowDateTimeString, setNowDateTimeString] = useState('');
    const [deletingReminderUuid, setDeletingReminderUuid] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const openEditModal = () => setIsEditModalOpen(true);
    const closeEditModal = () => setIsEditModalOpen(false);
    const openFlagModal = () => setIsFlagModalOpen(true);
    const closeFlagModal = () => setIsFlagModalOpen(false);
    const openCustomSnoozeModal = () => setIsCustomSnoozeModalOpen(true);
    const closeCustomSnoozeModal = () => setIsCustomSnoozeModalOpen(false);
    const [emptyReminderList, setEmptyReminderList] = useState(false);
    let setNeedsRefresh = user.setNeedsRefresh;

    const toFromfontSize = useBreakpointValue({ lg: "sm", xl: "sm", "2xl": "md" });
    const [selectedOptions, setSelectedOptions] = useState([...options]);

    const [sortOrder, setSortOrder] = useState('actionAsc'); // Default sorting order
    
    const [loadingStates, setLoadingStates] = useState({});
    const [loadingSnoozeHourStates, setLoadingSnoozeHourStates] = useState({});
    const [loadingSnoozeTomorrowStates, setLoadingSnoozeTomorrowStates] = useState({});
    
    const toast = useToast();


    useEffect(() => {
        if (user_details) {
          // Start with empty arrays for each category
          let receivedReminders = [];
          let selfReminders = [];
          let sentReminders = [];
      
          // If 'From' is selected, filter the received reminders
          if (selectedOptions.includes("From")) {
            receivedReminders = (user_details.received_reminders || []).filter(reminder => 
              !reminder.executed && !reminder.is_hidden
            );
          }
      
          // If 'Self' is selected, filter the self reminders
          if (selectedOptions.includes("Self")) {
            selfReminders = (user_details.self_reminders || []).filter(reminder => 
              !reminder.executed && !reminder.is_hidden
            );
          }
      
          // If 'To' is selected, filter the sent reminders
          if (selectedOptions.includes("To")) {
            sentReminders = (user_details.sent_reminders || []).filter(reminder => 
              !reminder.executed && !reminder.is_hidden
            );
          }
      
          // Combine the filtered reminders
          const combinedReminders = [
            ...receivedReminders,
            ...selfReminders,
            ...sentReminders
          ];

          // Sort the combined reminders based on the sortOrder state
            combinedReminders.sort((a, b) => {
                if (sortOrder === 'actionAsc') {
                    return a.reminder_action.localeCompare(b.reminder_action);
                } else if (sortOrder === 'actionDesc') {
                    return b.reminder_action.localeCompare(a.reminder_action);
                } else if (sortOrder === 'dateAsc') {
                    return new Date(a.local_fire_date_time) - new Date(b.local_fire_date_time);
                } else if (sortOrder === 'dateDesc') {
                    return new Date(b.local_fire_date_time) - new Date(a.local_fire_date_time);
                } else if (sortOrder === 'addedAsc') {
                    return new Date(b.date_added) - new Date(a.date_added);
                } else if (sortOrder === 'addedDesc') {
                    return new Date(a.date_added) - new Date(b.date_added);
                }
            });
      
          setReminderList(combinedReminders);
          if (combinedReminders.length === 0) {
            setEmptyReminderList(true);
          } else {
            setEmptyReminderList(false);
          }
        //   setIsLoading(false);
        }
    }, [user_details, selectedOptions, sortOrder]); // Add selectedOptions as a dependency
      

    useEffect(() => {
        const checkOverflow = () => {
          const current = listRef.current;
          if (!current) {
            return;
          }
    
          // Set state based on overflow
          setIsOverflowed(current.clientHeight < current.scrollHeight);
        };
    
        // Run on mount and every time the list changes
        checkOverflow();
    
        // Optional: if you expect dynamic content changes or window resizing
        window.addEventListener('resize', checkOverflow);

        // console.log("isOverFlowed is " + isOverflowed)
    
        // Cleanup event listener
        return () => window.removeEventListener('resize', checkOverflow);
    }, [reminderList]); 


    // Handler for selecting a sort option
    const handleSort = (order) => {
        setSortOrder(order);
    };

    function handleFlag(uuid){
        setReminderUuidToFlag(uuid);
        openFlagModal();
    }

    function handleFlagClose(){
        setReminderUuidToFlag(null);
        setFeedbackText('');
        closeFlagModal();
    }

    async function handleFlagSubmit(){
        setFeedbackLoading(true);
        let obj = {};
        obj.supertokens_id = user_details.supertokens_id;
        obj.reminder_uuid = reminderUuidToFlag;
        obj.feedback_text = feedbackText;
        try{
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/reminder-feedback", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
            if (res.status ===200) {
                // console.log("default channel updated successfully");
                // console.log(e)
                toast({
                    title: "Feedback Submitted!",
                    status: "success",
                    isClosable: true
                })
            } else {
                console.log("some error occurred");
                // logtail.error(user_details.email + ": some error occurred while setting default channel")
                // logtail.flush()
                toast({
                    title: "some error occurred",
                    status: "error",
                    isClosable: true
                })
            }
            // setReminderUuidToFlag(null);
        } catch (err) {
            // logtail.error(user_details.email + ": some error occurred while setting default channel", err)
            // logtail.flush()
            console.log(err);
            
        }
        setFeedbackText('');
        setReminderUuidToFlag(null);
        setFeedbackLoading(false);
        closeFlagModal();
        
    }

    function handleEdit(reminder){
        // // console.log(e.target.parentElement.id)
        // // console.log(uuid + " edit clicked")
        setReminderToEdit(reminder);
        let editFireDate = '';
        if (reminder.is_corrected){
            setEditReminderAction(reminder.corrected_action);
            editFireDate = convertToDateTimeLocalString(reminder.corrected_local_fire_date_time);
        } else {
            setEditReminderAction(reminder.reminder_action);
            editFireDate = convertToDateTimeLocalString(reminder.local_fire_date_time);
        }
        const now = new Date();
        const dateTimeString = now.toISOString().substring(0,16);
        setNowDateTimeString(dateTimeString);
        setEditFireDate(editFireDate);
        openEditModal();
    }

    const handleSnoozeHour = async (reminder) => {
        // console.log("snooze 1 hour")
        // console.log(reminder)
        setLoadingSnoozeHourStates(prev => ({ ...prev, [reminder.uuid]: true }));
        const obj = {
            reminder_uuid: reminder.uuid,
            snooze_minutes: 60
        }

        try{
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/snooze", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
            if (res.status ===200) {
                // console.log("default channel updated successfully");
                // console.log(e)
                toast({
                    title: "We snoozed your reminder for 1 hour!",
                    status: "success",
                    isClosable: true
                })
                setNeedsRefresh(true);
            } else {
                console.log("An error occurred trying to snooze the reminder for 1 hour.");
                // logtail.error(user_details.email + ": some error occurred while setting default channel")
                // logtail.flush()
                toast({
                    title: "Please try again",
                    status: "error",
                    isClosable: true
                })
            }
            // setReminderUuidToFlag(null);
        } catch (err) {
            // logtail.error(user_details.email + ": some error occurred while setting default channel", err)
            // logtail.flush()
            console.log(err);
            toast({
                title: "Please try again",
                status: "error",
                isClosable: true
            })
        } finally {
            // setDeletingReminderUuid(null);
            setLoadingSnoozeHourStates(prev => ({ ...prev, [reminder.uuid]: false }));
        }
    }

    const handleSnoozeTomorrow = async (reminder) => {
        console.log("snooze tomorrow")
        console.log(reminder)
        setLoadingSnoozeTomorrowStates(prev => ({ ...prev, [reminder.uuid]: true }));
        const obj = {
            reminder_uuid: reminder.uuid,
            snooze_tomorrow: true
        }

        try{
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/snooze", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
            if (res.status ===200) {
                // console.log("default channel updated successfully");
                // console.log(e)
                toast({
                    title: "We snoozed your reminder until tomorrow!",
                    status: "success",
                    isClosable: true
                })
                setNeedsRefresh(true);
            } else {
                console.log("An error occurred trying to snooze the reminder until tomorrow.");
                // logtail.error(user_details.email + ": some error occurred while setting default channel")
                // logtail.flush()
                toast({
                    title: "Please try again",
                    status: "error",
                    isClosable: true
                })
            }
            // setReminderUuidToFlag(null);
        } catch (err) {
            // logtail.error(user_details.email + ": some error occurred while setting default channel", err)
            // logtail.flush()
            console.log(err);
            toast({
                title: "Please try again",
                status: "error",
                isClosable: true
            })
        } finally {
            // setDeletingReminderUuid(null);
            setLoadingSnoozeTomorrowStates(prev => ({ ...prev, [reminder.uuid]: false }));
        }
    }

    function handleSnoozeCustom(reminder){
        console.log("snooze custom")
        console.log(reminder)
        let editFireDate = '';
        setReminderToCustomSnooze(reminder);
        if (reminder.is_corrected){
            setEditReminderAction(reminder.corrected_action);
            editFireDate = convertToDateTimeLocalString(reminder.corrected_local_fire_date_time);
        } else {
            setEditReminderAction(reminder.reminder_action);
            editFireDate = convertToDateTimeLocalString(reminder.local_fire_date_time);
        }
        const now = new Date();
        const dateTimeString = now.toISOString().substring(0,16);
        setNowDateTimeString(dateTimeString);
        setEditFireDate(editFireDate);
        openCustomSnoozeModal();
    }

    const handleEditSubmit = async () => {
        setEditLoading(true);
        const obj = {
            action: 'edit',
            reminder_uuid: reminderToEdit.uuid,
            reminder_action: editReminderAction,
            new_fire_date_time: editFireDate
        }
        // console.log(obj);
        try{
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/r/" + reminderToEdit.uuid, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
            if (res.status ===200) {
                // console.log("default channel updated successfully");
                // console.log(e)
                toast({
                    title: "Your reminder has been edited!",
                    status: "success",
                    isClosable: true
                })
                setNeedsRefresh(true);
            } else {
                console.log("some error occurred");
                // logtail.error(user_details.email + ": some error occurred while setting default channel")
                // logtail.flush()
                toast({
                    title: "some error occurred",
                    status: "error",
                    isClosable: true
                })
            }
            // setReminderUuidToFlag(null);
        } catch (err) {
            // logtail.error(user_details.email + ": some error occurred while setting default channel", err)
            // logtail.flush()
            console.log(err);
            toast({
                title: "some error occurred",
                status: "error",
                isClosable: true
            })
        }
        setEditReminderAction('');
        setEditFireDate('');
        setReminderToEdit(null);
        setEditLoading(false);
        closeEditModal();
    };

    const handleCustomSnoozeSubmit = async () => {
        setCustomSnoozeLoading(true);
        const obj = {
            action: 'snooze',
            reminder_uuid: reminderToCustomSnooze.uuid,
            reminder_action: editReminderAction,
            new_fire_date_time: editFireDate
        }
        try{
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/r/" + reminderToCustomSnooze.uuid, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
            if (res.status ===200) {
                // console.log("default channel updated successfully");
                // console.log(e)
                toast({
                    title: "We snoozed your reminder!",
                    status: "success",
                    isClosable: true
                })
                setNeedsRefresh(true);
            } else {
                console.log("An error occurred trying to custom snooze.");
                // logtail.error(user_details.email + ": some error occurred while setting default channel")
                // logtail.flush()
                toast({
                    title: "Please try again",
                    status: "error",
                    isClosable: true
                })
            }
            // setReminderUuidToFlag(null);
        } catch (err) {
            // logtail.error(user_details.email + ": some error occurred while setting default channel", err)
            // logtail.flush()
            console.log(err);
            toast({
                title: "Please try again",
                status: "error",
                isClosable: true
            })
        } finally {
            setEditReminderAction('');
            setEditFireDate('');
            setReminderToCustomSnooze(null);
            setCustomSnoozeLoading(false);
            closeCustomSnoozeModal();
        }
    }

    const handleDelete = async (uuid) => {
        setDeletingReminderUuid(uuid);
        const obj = {
            action: 'delete',
            reminder_uuid: uuid,
        }
        // console.log(obj);
        try{
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/r/" + uuid, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
            if (res.status ===200) {
                // console.log("default channel updated successfully");
                // console.log(e)
                toast({
                    title: "Your reminder has been completed!",
                    status: "success",
                    isClosable: true
                })
                setNeedsRefresh(true);
            } else {
                console.log("some error occurred");
                // logtail.error(user_details.email + ": some error occurred while setting default channel")
                // logtail.flush()
                toast({
                    title: "some error occurred",
                    status: "error",
                    isClosable: true
                })
            }
            // setReminderUuidToFlag(null);
        } catch (err) {
            // logtail.error(user_details.email + ": some error occurred while setting default channel", err)
            // logtail.flush()
            console.log(err);
            toast({
                title: "some error occurred",
                status: "error",
                isClosable: true
            })
        } finally {
            // setDeletingReminderUuid(null);
        }
    }

    const handleSelectionChange = (selectedOptions) => {
        console.log(selectedOptions);
        // const updatedList = reminderList.filter((reminder) =>
        //   selectedOptions.includes(reminder.type) // assuming each reminder has a type
        // );
        // setReminderList(updatedList);
      };

    const filterSortBar = () => {
        return (
            <HStack width="full" justifyContent="center" alignItems="center">
                <Box width={'40px'} />
                <Spacer />
                <SegmentedControl onSelectionChange={handleSelectionChange} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions}/>
                <Spacer />
                <Menu>
                    <MenuButton 
                        as={IconButton} 
                        aria-label="Sort" 
                        icon={<SortIcon />} 
                        variant="soloid" 
                        bg={'brand.orange'} 
                        borderRadius={'full'}
                        _hover={{bg: 'brand.orange_hover'}}
                    />
                    <MenuList>
                        <MenuItem onClick={() => handleSort('actionAsc')} fontWeight={sortOrder === 'actionAsc' ? 'bold' : 'normal'}>Action (A-Z)</MenuItem>
                        <MenuItem onClick={() => handleSort('actionDesc')} fontWeight={sortOrder === 'actionDesc' ? 'bold' : 'normal'}>Action (Z-A)</MenuItem>
                        <MenuItem onClick={() => handleSort('dateAsc')} fontWeight={sortOrder === 'dateAsc' ? 'bold' : 'normal'}>Notification Date (soonest)</MenuItem>
                        <MenuItem onClick={() => handleSort('dateDesc')} fontWeight={sortOrder === 'dateDesc' ? 'bold' : 'normal'}>Notification Date (latest)</MenuItem>
                        <MenuItem onClick={() => handleSort('addedAsc')} fontWeight={sortOrder === 'addedAsc' ? 'bold' : 'normal'}>Date Added (newest)</MenuItem>
                        <MenuItem onClick={() => handleSort('addedDesc')} fontWeight={sortOrder === 'addedDesc' ? 'bold' : 'normal'}>Date Added (oldest)</MenuItem>
                    </MenuList>
                </Menu>
            </HStack>
        )
    }

    const emptyListGraphic = () => {
        return (
            <Center pt={10}>
                <Text fontSize={{lg: "lg", xl: "xl", "2xl": "2xl"}} color={'blackAlpha.700'}>You don't have any reminders — set one above!</Text>
            </Center>
        )
    }

    const buildReminder = (reminder) => {
        let date;
        let action;
        if (reminder.is_corrected){
            date = new Date(reminder.corrected_local_fire_date_time);
            action = reminder.corrected_action;
        } else {
            date = new Date(reminder.local_fire_date_time);
            action = reminder.reminder_action;
        }
        const formattedDate = date.toLocaleString('en-US', {
            month: 'long', // Display the full month name
            day: 'numeric', // Display the day as a number
            year: 'numeric', // Display the full year
            hour: 'numeric', // Display the hour
            minute: '2-digit', // Display the minute with at least 2 digits
            // second: '2-digit', // Display the second with at least 2 digits
        });

        // Define a function to handle the icon click
        const handleIconClick = (uuid) => {
            // Update the loading state for this specific reminder
            setLoadingStates(prev => ({ ...prev, [uuid]: true }));
            handleDelete(uuid);
            setLoadingStates(prev => ({ ...prev, [uuid]: false }));
        };
        
        if (reminder.sent) {
            return (
                <AccordionItem key={reminder.uuid} border={"0px"} pb={1} 
                // sx={{
                //     boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.9)',
                // }}
                >
                    {({ isExpanded }) => (
                        <>
                            <Grid
                                templateColumns={'repeat(23, 1fr)'}
                                templateAreas={`"check check check action action action action action action action action action action action action action action tofrom tofrom bell bell accordion accordion"
                                                "check check check time time time time time time time time time time time time time time tofrom tofrom bell bell accordion accordion"`}
                                // templateRows='repeat(2, 1fr)'
                                // templateColumns='repeat(20, 1fr)'
                                gap={0}
                                bg={'yellow.50'}
                                // borderRadius={isExpanded ? "3xl 3xl 0 0" : "3xl"}
                                borderTopRadius={"3xl"}
                                borderBottomRadius={isExpanded ? "0" : "3xl"}
                                
                                >
                                <GridItem
                                    // bg={'red.100'}
                                    area={'check'}
                                    display="flex" // This enables the flexbox layout
                                    justifyContent="center" // This centers the content horizontally
                                    alignItems="center" // This centers the content vertically
                                >
                                    <Tooltip label="Mark as completed" aria-label="Mark as completed">
                                        <Center>
                                            {loadingStates[reminder.uuid] ? (
                                                <Spinner size="lg" />
                                            ) :
                                                <Icon 
                                                    as={CheckCircleOutlineIcon} 
                                                    id={reminder.uuid} 
                                                    onClick={() => handleIconClick(reminder.uuid)} 
                                                    boxSize={{lg: 8, xl: 10, "2xl": 12}}
                                                    style={{
                                                        color: 'grey', 
                                                        cursor: 'pointer',
                                                        
                                                    }}
                                                />
                                            }
                                        </Center>
                                    </Tooltip>
                                </GridItem>
                                
                                <GridItem bg={''} area={'action'}>
                                    <Text isTruncated minWidth={"0"} fontSize={{lg: "lg", xl: "xl", "2xl": "2xl"}} color={'blackAlpha.800'}>{action}</Text>
                                </GridItem>
                            
                                <GridItem bg={''} area={'time'}>
                                    <Text fontSize={{lg: "sm", xl: "sm", "2xl": "md"}} color={'blackAlpha.800'}>{formattedDate}</Text>
                                </GridItem>
                                <GridItem
                                    area={'tofrom'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    {/* {buildToFromBox(reminder)} */}
                                    <ReminderToFromBox reminder={reminder} supertokensId={user_details.supertokens_id} />
                                </GridItem>
                                <GridItem
                                    area={'bell'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    {/* {buildToFromBox(reminder)} */}
                                    <NotificationsActiveIcon style={{color: 'blackAlpha.700'}} />
                                </GridItem>
                                <GridItem
                                    // bg={'red.100'}
                                    id='accordion-button-grid'
                                    area={'accordion'}
                                    display="flex" // This enables the flexbox layout
                                    justifyContent="center" // This centers the content horizontally
                                    alignItems="center" // This centers the content vertically
                                >
                                    <AccordionButton aria-label={'more actions for ' + reminder.reminder_action + ' reminder'}>
                                        <Center id='accordion-button-center' w={"100%"}>
                                            <AccordionIcon />
                                        </Center>
                                    </AccordionButton>
                                </GridItem>
                                
                            </Grid>
                            <AccordionPanel
                                bg={'yellow.50'}
                                borderBottomRadius={"3xl"}
                                pb={2}
                                // sx={{
                                //     boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.9)',
                                // }}
                            >
                                <Flex direction={"row"}>
                                    <Spacer />
                                    <Button
                                        size={{lg: "sm", xl: "md", "2xl": "md"}} 
                                        leftIcon={<SnoozeIcon style={{color: 'white'}} />} 
                                        isLoading={loadingSnoozeHourStates[reminder.uuid]}
                                        loadingText="Snoozing"
                                        bg={'brand.violet'}
                                        onClick={() => handleSnoozeHour(reminder)} 
                                        _hover={{
                                            bg: 'brand.violet_hover', // darken the button on hover
                                            // transform: 'scale(1.05)', // scale up the button on hover
                                            boxShadow: 'xl', // even larger shadow on hover
                                        }}   
                                    >
                                        <Text color={'white'} fontWeight={'bold'}>
                                            1 hour
                                        </Text>
                                    </Button>
                                    <Spacer />
                                    <Button
                                        size={{lg: "sm", xl: "md", "2xl": "md"}} 
                                        leftIcon={<SnoozeIcon style={{color: 'white'}} />} 
                                        isLoading={loadingSnoozeTomorrowStates[reminder.uuid]}
                                        loadingText="Snoozing"
                                        bg={'brand.violet'}
                                        onClick={() => handleSnoozeTomorrow(reminder)} 
                                        _hover={{
                                            bg: 'brand.violet_hover', // darken the button on hover
                                            // transform: 'scale(1.05)', // scale up the button on hover
                                            boxShadow: 'xl', // even larger shadow on hover
                                        }}   
                                    >
                                        <Text color={'white'} fontWeight={'bold'}>
                                            tomorrow
                                        </Text>
                                    </Button>
                                    <Spacer />
                                    <Button
                                        size={{lg: "sm", xl: "md", "2xl": "md"}} 
                                        leftIcon={<SnoozeIcon style={{color: 'white'}} />} 
                                        bg={'brand.violet'}
                                        onClick={() => handleSnoozeCustom(reminder)} 
                                        _hover={{
                                            bg: 'brand.violet_hover', // darken the button on hover
                                            // transform: 'scale(1.05)', // scale up the button on hover
                                            boxShadow: 'xl', // even larger shadow on hover
                                        }}   
                                    >
                                        <Text color={'white'} fontWeight={'bold'}>
                                            custom ...
                                        </Text>
                                    </Button>
                                    <Spacer />
                                    <IconButton 
                                        size={{lg: "sm", xl: "md", "2xl": "md"}} 
                                        icon={<FlagRoundedIcon style={{color: 'white'}} />} 
                                        bg={'brand.violet'}
                                        onClick={() => handleFlag(reminder.uuid)}
                                        _hover={{
                                            bg: 'brand.violet_hover', // darken the button on hover
                                            // transform: 'scale(1.05)', // scale up the button on hover
                                            boxShadow: 'xl', // even larger shadow on hover
                                        }}
                                    >
                                        <Text color={'white'} fontWeight={'bold'}>
                                        </Text>
                                    </IconButton>
                                    <Spacer />
                                </Flex>
                                
                            </AccordionPanel>
                        </>
                    )}
                </AccordionItem>
                
            //</Accordion>

        );
        } else {
            return (
                // <Accordion 
                //     allowToggle 
                //     border={"0px"}
                //     // onChange={handleExpandedReminder(reminder.uuid)}
                // >
                    <AccordionItem key={reminder.uuid} border={"0px"} pb={1}>
                        {({ isExpanded }) => (
                            <>
                                <Grid
                                    templateColumns={'repeat(23, 1fr)'}
                                    templateAreas={`"check check check action action action action action action action action action action action action action action tofrom tofrom tofrom tofrom accordion accordion"
                                                    "check check check time time time time time time time time time time time time time time tofrom tofrom tofrom tofrom accordion accordion"`}
                                    // templateRows='repeat(2, 1fr)'
                                    // templateColumns='repeat(20, 1fr)'
                                    gap={0}
                                    bg={'whiteAlpha.700'}
                                    // borderRadius={isExpanded ? "3xl 3xl 0 0" : "3xl"}
                                    borderTopRadius={"3xl"}
                                    borderBottomRadius={isExpanded ? "0" : "3xl"}
                                    
                                    >
                                    <GridItem
                                        // bg={'red.100'}
                                        area={'check'}
                                        display="flex" // This enables the flexbox layout
                                        justifyContent="center" // This centers the content horizontally
                                        alignItems="center" // This centers the content vertically
                                    >
                                        <Tooltip label="Mark as completed" aria-label="Mark as completed">
                                            <Center>
                                                {loadingStates[reminder.uuid] ? (
                                                    <Spinner size="lg" />
                                                ) :
                                                    <Icon 
                                                        as={CheckCircleOutlineIcon} 
                                                        id={reminder.uuid} 
                                                        onClick={() => handleIconClick(reminder.uuid)} 
                                                        boxSize={{lg: 8, xl: 10, "2xl": 12}}
                                                        style={{
                                                            color: 'grey', 
                                                            cursor: 'pointer',
                                                        
                                                        }}
                                                    />
                                                }
        
                                            </Center>
                                        </Tooltip>
                                    </GridItem>
                                    
                                    <GridItem bg={''} area={'action'}>
                                        <Text isTruncated minWidth={"0"} fontSize={{lg: "lg", xl: "xl", "2xl": "2xl"}} color={'blackAlpha.800'}>{action}</Text>
                                    </GridItem>
                                
                                    <GridItem bg={''} area={'time'}>
                                        <Text fontSize={{lg: "sm", xl: "sm", "2xl": "md"}} color={'blackAlpha.800'}>{formattedDate}</Text>
                                    </GridItem>
                                    <GridItem
                                        area={'tofrom'}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        {/* {buildToFromBox(reminder)} */}
                                        <ReminderToFromBox reminder={reminder} supertokensId={user_details.supertokens_id} />
                                    </GridItem>
                                    <GridItem
                                        // bg={'red.100'}
                                        id='accordion-button-grid'
                                        area={'accordion'}
                                        display="flex" // This enables the flexbox layout
                                        justifyContent="center" // This centers the content horizontally
                                        alignItems="center" // This centers the content vertically
                                    >
                                        <AccordionButton >
                                            <Center id='accordion-button-center' w={"100%"}>
                                                <AccordionIcon />
                                            </Center>
                                        </AccordionButton>
                                    </GridItem>
                                    
                                </Grid>
                                <AccordionPanel
                                    bg={'whiteAlpha.700'}
                                    borderBottomRadius={"3xl"}
                                    pb={2}
                                >
                                    <Flex direction={"row"}>
                                        <Spacer />
                                        <Button
                                            size={{lg: "sm", xl: "md", "2xl": "md"}} 
                                            leftIcon={<EditRoundedIcon style={{color: 'white'}} />} 
                                            bg={'brand.violet'}
                                            onClick={() => handleEdit(reminder)} 
                                            _hover={{
                                                bg: 'brand.violet_hover', // darken the button on hover
                                                // transform: 'scale(1.05)', // scale up the button on hover
                                                boxShadow: 'xl', // even larger shadow on hover
                                            }}   
                                        >
                                            <Text color={'white'} fontWeight={'bold'}>
                                                Edit
                                            </Text>
                                        </Button>
                                        <Spacer />
                                        <Button 
                                            size={{lg: "sm", xl: "md", "2xl": "md"}} 
                                            leftIcon={<FlagRoundedIcon style={{color: 'white'}} />} 
                                            bg={'brand.violet'}
                                            onClick={() => handleFlag(reminder.uuid)}
                                            _hover={{
                                                bg: 'brand.violet_hover', // darken the button on hover
                                                // transform: 'scale(1.05)', // scale up the button on hover
                                                boxShadow: 'xl', // even larger shadow on hover
                                            }}
                                        >
                                            <Text color={'white'} fontWeight={'bold'}>
                                                Flag a problem
                                            </Text>
                                        </Button>
                                        <Spacer />
                                    </Flex>
                                    
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                //</Accordion>
    
            );

        }       
        
    }
    if (user.loading) {
        return (
            <Center>
                <Spinner size="xl" />
            </Center>
        );
    }

    return (
        <VStack>
            {filterSortBar()}
            {emptyReminderList && emptyListGraphic()}
            <Center flex={"1"} >
                {/* Custom Snooze Modal */}
                <Modal isOpen={isCustomSnoozeModalOpen} onClose={closeCustomSnoozeModal} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Snooze Reminder
                            <ModalCloseButton />
                        </ModalHeader>
                        <ModalBody>
                            <Text fontSize={'xl'} pb={4}>{editReminderAction}</Text>
                            {/* <Input 
                                type={'text'} 
                                name={'new_reminder_action'} 
                                value={editReminderAction}
                                onChange={(e) => setEditReminderAction(e.target.value)}
                            /> */}
                            {/* TODO: This renders the date/time picker in the browser's local time.  May need to fix this in the future */}
                            <Input 
                                type={'datetime-local'} 
                                min={nowDateTimeString}
                                value={editFireDate}
                                onChange={(e) => setEditFireDate(e.target.value)}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Wrap>
                                <Button 
                                    isLoading={customSnoozeLoading}
                                    loadingText="Snooozing"
                                    bg={'brand.green'}
                                    onClick={handleCustomSnoozeSubmit}
                                    _hover={{
                                        bg: 'brand.green_hover', // darken the button on hover
                                        // transform: 'scale(1.05)', // scale up the button on hover
                                        boxShadow: 'xl', // even larger shadow on hover
                                    }}   
                                >
                                    Snooze
                                </Button>
                            </Wrap>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                {/* Edit Modal */}
                <Modal isOpen={isEditModalOpen} onClose={closeEditModal} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Reminder
                            <ModalCloseButton />
                        </ModalHeader>
                        <ModalBody>
                            
                            <Input 
                                type={'text'} 
                                name={'new_reminder_action'} 
                                value={editReminderAction}
                                onChange={(e) => setEditReminderAction(e.target.value)}
                            />
                            {/* TODO: This renders the date/time picker in the browser's local time.  May need to fix this in the future */}
                            <Input 
                                type={'datetime-local'} 
                                min={nowDateTimeString}
                                value={editFireDate}
                                onChange={(e) => setEditFireDate(e.target.value)}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Wrap>
                                <Button 
                                    isLoading={editLoading}
                                    loadingText="Submitting"
                                    bg={'brand.green'}
                                    onClick={handleEditSubmit}
                                    _hover={{
                                        bg: 'brand.green_hover', // darken the button on hover
                                        // transform: 'scale(1.05)', // scale up the button on hover
                                        boxShadow: 'xl', // even larger shadow on hover
                                    }}   
                                >
                                    Submit
                                </Button>
                            </Wrap>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                {/* Flag Modal */}
                <Modal isOpen={isFlagModalOpen} onClose={handleFlagClose} isCentered>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Did we get this reminder wrong?
                                <ModalCloseButton />
                            </ModalHeader>
                            <ModalBody>
                                <Text pb={4}>Please describe what you expected to happen with this reminder. We will review it and try to tweak the algorithm.</Text>
                                <Textarea
                                    placeholder="Describe the issue..."
                                    minH="unset" // Remove the minimum height
                                    overflow="hidden" // Prevent scrollbar appearance
                                    resize="none" // Prevent manual resizing
                                    // minRows={2} // Start with two rows
                                    // Optional: If you want the Textarea to grow automatically
                                    onChange={(e) => {
                                        e.target.style.height = 'unset'; // Reset the height
                                        e.target.style.height = `${e.target.scrollHeight}px`; // Set the height based on scroll height
                                        setFeedbackText(e.target.value);
                                    }}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Wrap>
                                    <Button 
                                        isLoading={feedbackLoading}
                                        loadingText="Submitting"
                                        bg={'brand.green'}
                                        onClick={handleFlagSubmit}
                                        _hover={{
                                            bg: 'brand.green_hover', // darken the button on hover
                                            // transform: 'scale(1.05)', // scale up the button on hover
                                            boxShadow: 'xl', // even larger shadow on hover
                                        }}   
                                    >
                                        Submit
                                    </Button>
                                </Wrap>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                
                <Box w={'100%'} 
                    // maxH={'275px'} 
                    maxH={{base: "220", sm: "220", md: "250", lg: "270", xl: "320", "2xl": "320"}}
                    // flex={"1"} 
                    overflowY="auto"
                    position={"relative"}
                    overflowX={"hidden"}
                    ref={listRef}
                >
                    <Accordion
                        allowMultiple
                        border={"0px"}
                    >
                        {reminderList.map(reminder => buildReminder(reminder))}
                    </Accordion>
                </Box>
                {isOverflowed && (
                    <Box
                        position="absolute"
                        bottom="0"
                        left="0"
                        right="0"
                        height="40px"
                        bgGradient="linear(to-b, transparent, rgba(217, 227, 243, 0.5)"
                    />
                )}
            </Center>
        </VStack>
    );


}

const ReminderToFromBox = ({ reminder, supertokensId }) => {
    const [avatar, setAvatar] = useState({ imageUrl: '', name: '', initials: '' });

    useEffect(() => {
        let idToFetch = reminder.owner_id !== supertokensId ? reminder.owner_id : reminder.target_user_id;
        
        const fetchAvatar = async () => {
            if (!idToFetch) return; // If there's no ID to fetch, exit early.

            try {
                const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/avatar/${idToFetch}`);
                const data = await response.json();

                let avatarData = { imageUrl: '', name: '', initials: '' };

                if (data.avatarUrl) {
                    avatarData.imageUrl = data.avatarUrl;
                    avatarData.name = data.name;
                } else if (data.initials) {
                    avatarData.initials = data.initials;
                    avatarData.name = data.name;
                }
                setAvatar(avatarData);
            } catch (error) {
                console.error('Error fetching avatar:', error);
            }
        };

        fetchAvatar();
    }, [reminder.owner_id, reminder.target_user_id, supertokensId]); 
    

    if (reminder.owner_id !== supertokensId){
        //build receiver icon
        const capitalizedTarget = avatar.name.charAt(0).toUpperCase() + avatar.name.slice(1);
        return (
            <Tooltip label={'You received this reminder from ' + capitalizedTarget} placement={'top'} hasArrow>
                <Box bg={'brand.green'} pr={2} pl={2} borderRadius={'full'}>
                    <HStack>
                        <Text color={'blackAlpha.700'} fontWeight={'bold'}>
                            From
                        </Text>
                        <Avatar size='2xs' name={avatar.name} src={avatar.imageUrl} />
                    </HStack>
                </Box>
            </Tooltip>
        )
    }
    else if (reminder.target_user_id !== supertokensId){
        // build sender icon
        const capitalizedTarget = avatar.name.charAt(0).toUpperCase() + avatar.name.slice(1);
        return (
            <Tooltip label={'You sent this reminder to ' + capitalizedTarget} placement={'top'} hasArrow>
                <Box bg={'brand.green'} pr={2} pl={2} borderRadius={'full'}>
                    <HStack>
                        <Text color={'blackAlpha.700'} fontWeight={'bold'}>
                            To
                        </Text>
                        <Avatar size='2xs' name={avatar.name} src={avatar.imageUrl} />
                    </HStack>
                </Box>
            </Tooltip>
        )
    }
    else {
        // return empty box
        return (
            <Box></Box>
        )
    }
}

function convertToDateTimeLocalString(isoString) {
    const date = new Date(isoString);
    // Get YYYY-MM-DD format
    const datePart = date.toISOString().split('T')[0];
    // Get hh:mm format - convert to local time
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');

    // Combine for datetime-local input value
    return `${datePart}T${hours}:${minutes}`;
}

export default LiveReminderList;