import React from 'react';
import { Flex, Heading, Text, Box, VStack } from '@chakra-ui/react';
import TopBar from './TopBar';

function Welcome() {
  return (
    <Box>
      <TopBar />
      <Flex 
            w={"100%"} 
            minH={"calc(100vh - 248px)"}
            justify={'center'} 
        >
            <VStack>
                <Heading as="h1" size="xl" mb={4}>
                Welcome to ReminderLoop
                </Heading>
                <Text fontSize="lg">
                Thank you for choosing ReminderLoop. We are excited to have you as our customer!
                </Text> 
            </VStack>
      </Flex>
    </Box>
  );
}

export default Welcome;
