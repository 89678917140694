import React, {useState, useContext, useEffect} from 'react';
import {
    Box,
    Center,
    Text,
    Heading,
    Divider,
    Image,
    Button,
    IconButton,
    useToast,
    Spinner,
    useDisclosure,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    ModalOverlay,
    ModalContent,
    ModalCloseButton, ModalBody, HStack, ModalHeader, ListItem, Flex, Modal, createIcon, VStack, Spacer, OrderedList
} from '@chakra-ui/react';
import { SignInAndUp } from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui";
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Session from "supertokens-auth-react/recipe/session";
// import {useSessionContext} from "supertokens-auth-react/recipe/session";
import {UserContext} from "../UserContext";
import Features from './Features';
import SettingsDrawer from './SettingsDrawer';
import {
    GooglePlayButton,
    AppStoreButton,
    ButtonsContainer,
  } from "react-mobile-app-button";
import ReminderInputBox from "./ReminderInputBox";
import Testimonials from './Testimonials';
// import ComparisonTable from './ComparisonTable';
// import Typewriter from 'typewriter-effect';
import CookieConsent from "react-cookie-consent";
import { useNavigate } from "react-router-dom";
import DummyReminders from './DummyReminderList';
import LiveReminderList from './LiveReminderList';
// import Pricing from './Pricing';
// import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
// import StartRoundedIcon from '@mui/icons-material/StartRounded';
import MenuIcon from '@mui/icons-material/Menu';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Formik } from 'formik';
import DecorativePricingTable from './DecorativePricingTable';

function HomeMobile(props){
    let horizontalPadding = 14;
    let logoFontSize = {base: 24, sm: 28, md: 32,};
    const APKUrl = "https://play.google.com/store/apps/details?id=com.reminderloop.reminderloop&hl=en-US&ah=okjoD1wkLDJUUUXmZUuf1sLBgxY";
    // const APKUrl = "";
    // const IOSUrl = "https://apps.apple.com/us/app/reminderloop/id6479958772";
    const IOSUrl = "";
    const buttonHeight = 50;
    const buttonWidth = 185;
    const highlightColor = 'brand.yellow';
    // const leftColMaxWidth = '780px';
    // const colWidth = {lg: "600px", xl: "700px", "2xl": "780px"};
    const maxColWidth = {base: "100%", sm: "90%", md: "85%"};
    const user = useContext(UserContext);
    let user_details = user.user_details;
    let setNeedsRefresh = user.setNeedsRefresh;
    let refreshLoading = user.refreshLoading;
    let sessionContext = Session.useSessionContext();
    let {doesSessionExist, userId, accessTokenPayload} = sessionContext;
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
    // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    // const [reminderInput, setReminderInput] = useState('');
    let [newReminder, setNewReminder] = useState({})
    let [newReminderPopNeeded, setNewReminderPopNeeded] = useState(false)
    let [addChannelPopNeeded, setAddChannelPopNeeded] = useState(false)
    let [logInPromptNeeded, setLogInPromptNeeded] = useState(false)
    let [loading, setLoading] = useState(false)
    const [reminderToAdd, setReminderToAdd] = useState({});
    let [reminderBoxHeader, setReminderBoxHeader] = useState('Try setting a reminder now!');
    const toast = useToast();
    // let mode = 'demo';
    let [mode, setMode] = useState('demo');
    const { isOpen: isSettingsOpen, onOpen: onSettingsOpen, onClose: onSettingsClose } = useDisclosure();

    // login modal
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
        setIsModalOpen(false);
        user.makeChoice(null);
    }

    // sign up modal
    const openSignUpModal = () => setIsSignUpModalOpen(true);
    const closeSignUpModal = () => {
        setIsSignUpModalOpen(false);
        user.makeChoice(null);
    }

    async function onLogout() {
        await signOut();
        window.location.href = "/";
    }
    

    useEffect(() => {
        if (sessionContext.loading) {
          return;
        }
        if (sessionContext.doesSessionExist) {
            setMode('live');
            closeModal();
            // console.log("user logged in")
            if (user.postLoginFreeOrPay) {
                if (user.postLoginFreeOrPay === 'pro') {
                    console.log("user wants to upgrade to pro")
                    openSignUpModal();
                }
            }
            if (user_details.first_name && user_details.first_name.trim() !== '') {
                setReminderBoxHeader('Welcome back, ' + user_details.first_name + '!');
            } else {
                setReminderBoxHeader('Welcome back!');
            }
        } else {
        //   console.log("user not logged in")
        }
    }, [sessionContext, user_details.first_name, user.postLoginFreeOrPay]);

    if (sessionContext.loading) {
        // console.log('sessionContext.loading: ' + sessionContext.loading)
        return null;
    }

    function handleRefreshClick() {
        console.log('refresh clicked')
        setNeedsRefresh(true);
    }

    let CTAButton = () => {
        let callBackFunction = null;
        let buttonText = '';
        let subText = '';
        let buttonColor = 'brand.yellow';
        let navToRemindersForOthers = () => navigate('/remindersforothers');

        if (doesSessionExist) {
            if (user_details.account_type === 'free') {
                callBackFunction = openSignUpModal;
                buttonText = "Upgrade to Pro!";
                subText = "Try for free for 2 weeks!";
            } else {
                callBackFunction = navToRemindersForOthers;
                buttonText = "Start Sharing Reminders!";
                subText = "Invite friends and family to share reminders with you!";
                buttonColor = 'brand.green'
            }
        } else {
            callBackFunction = openModal;
            buttonText = "Get Started Now For Free!";
            subText = "No credit card required!";
        }

        return (
            <Heading>
                <Center>
                    <Button 
                        pt={{lg: 6, xl: 6, "2xl": 8}}
                        pb={{lg: 6, xl: 6, "2xl": 8}}
                        pr={{lg: 8, xl: 8, "2xl": 10}}
                        pl={{lg: 8, xl: 8, "2xl": 10}}
                        fontSize={{base: 'xl', sm: 'xl', md: 'xl', lg: "2xl", xl: "2xl", "2xl": "3xl"}} 
                        color={'blackAlpha.700'} 
                        bg={buttonColor} 
                        // onClick={doesSessionExist ? openSignUpModal : openModal}
                        onClick = {callBackFunction}
                        minW={"300px"}
                        boxShadow="lg"
                        _hover={{
                            // bg: 'brand.yellow_hover', // darken the button on hover
                            transform: 'scale(1.05)', // scale up the button on hover
                            boxShadow: 'dark-lg', // even larger shadow on hover
                            }}
                    >  
                        {buttonText}
                    </Button>
                </Center>
                <Center>
                    <Text fontSize={'sm'} color={'blackAlpha.700'} pt={1}>
                    {subText}
                    </Text>
                </Center>
            </Heading>
        )
    }

    if (user.loading) {
        return (
            <Flex 
                w={"100%"} 
                h={"calc(100vh - 200px)"}
                justify={'center'} 
            >
                <Center>
                    <Spinner size={'xl'} />
                </Center>
            </Flex>
        )
    }

    return(
        <Box position="relative">
            <CookieConsent buttonStyle={{ background: "#9DE8D4", color: "black", fontSize: "10px" }}><span style={{ fontSize: "10px" }}>This website uses cookies to enhance the user experience.</span></CookieConsent>
            <VStack 
                spacing={0} 
                align={'center'} 
                bgGradient={'linear(to-tr, brand.pink, brand.violet 60%)'}
            >
                <Box pt={5} w={'100%'} maxW={maxColWidth}>
                    <VStack align="start" maxH={'3xl'} spacing={4} w={'100%'}>
                        <Box textStyle={'logo'} fontWeight={'bold'} pt={0} pl={horizontalPadding} color='blackAlpha.700'>
                            <HStack spacing={0}>
                                <Text fontSize={logoFontSize}>Reminder</Text>
                                <Text fontSize={logoFontSize}>L</Text>
                                {/*<Box transform={"translateX(-.05rem) translateY(-0.15rem) rotate(-30deg)"}*/}
                                <Box transform={"translateX(-3%) translateY(-0.15rem) rotate(-30deg)"} bgGradient={'linear(to-r, brand.o_beginning_logo, brand.o_ending_logo)'} bgClip={'text'}>
                                    <Text as={'span'} mr={'-10%'} fontSize={logoFontSize} >o</Text>
                                    <Text as={'span'} fontSize={logoFontSize} >o</Text>
                                </Box>
                                <Text  transform={'translateX(-20%)'} fontSize={logoFontSize}>p</Text>
                            </HStack>
                        </Box>
                        <Box pl={horizontalPadding} pr={horizontalPadding} pt={8} color='blackAlpha.700'>
                            <Heading as="h1" fontSize={{base: "3xl", sm: "3xl", md: "3xl"}} fontWeight={'bold'} lineHeight={'shorter'}>
                                Never forget a thing with <Box as="span" color={highlightColor}>ReminderLoop</Box>—your personal memory assistant.
                            </Heading>
                        </Box>
                        <Box as="section" pl={horizontalPadding} pr={horizontalPadding} pt={5} color='blackAlpha.700'>
                            <Text as="h2" fontSize={{base: "xl", sm: "xl", md: "xl"}} fontWeight={'bold'}>
                                Set effortless reminders in your own words, and get notifications right when you need them, for you or anyone you care about.
                            </Text>
                        </Box>
                        <Flex pt={4} flex={1} flexDirection="column" justifyContent="center" alignItems="center" w="100%" h="100%">
                            <CTAButton />
                            {/* <Heading>
                                <Button 
                                    py={{base: 6, sm: 6, md: 8}}
                                    px={{base: 8, sm: 8, md: 10}}
                                    fontSize={{base: "xl", sm: "xl", md: "xl"}} 
                                    color={'blackAlpha.700'} 
                                    bg={'brand.yellow'} 
                                    onClick={doesSessionExist ? openSignUpModal : openModal}
                                    minW={"300px"}
                                    boxShadow="lg"
                                    _hover={{
                                        // bg: 'brand.yellow_hover', // darken the button on hover
                                        transform: 'scale(1.05)', // scale up the button on hover
                                        boxShadow: 'dark-lg', // even larger shadow on hover
                                        }}
                                >  
                                    {doesSessionExist ? "Upgrade to Pro!" : "Get Started Now For Free!"}
                                </Button>
                                <Center>
                                    <Text fontSize={'sm'} color={'blackAlpha.700'} pt={1}>
                                    {doesSessionExist ? "Try for free for 2 weeks!" : "No credit card required!"}
                                    </Text>
                                </Center>
                            </Heading> */}
                        </Flex> 
                        <Box w={"100%"} pl={0} pr={0} pt={10} pb={5}color='blackAlpha.700' >
                            <Flex justifyContent={'center'}>
                                <ButtonsContainer>
                                    <GooglePlayButton
                                        url={APKUrl}
                                        theme={"dark"}
                                        height={buttonHeight}
                                        width={buttonWidth}
                                        // className={"custom-button-style"}
                                    />
                                    <AppStoreButton
                                        url={IOSUrl}
                                        theme={"dark"}
                                        height={buttonHeight}
                                        width={buttonWidth}
                                        // className={"custom-button-style"}
                                    />
                                </ButtonsContainer>
                            </Flex>
                            <Flex w={'100%'} justify={'center'}>
                                <Text fontStyle={'italic'} pl={"180px"}>Coming Soon!</Text>
                            </Flex>
                        </Box>
                    </VStack> 
                </Box>
                <Divider w={maxColWidth} />
                <Flex 
                    pt={0} 
                    h={{base: 'xl', sm: 'xl', md: '2xl'}} 
                    w={'100%'} 
                    maxW={maxColWidth} 
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Box 
                        // minW={{ base: '90%', md: 'xl' }} 
                        id='reminderloop-app'
                        w={{base: "95%", sm: "90%", md: "90%"}} 
                        // maxW={{ base: '100%', md: '2xl', lg: '2xl', "2xl": '3xl'}} 
                        h={{base: "lg", sm: "lg", md: "xl"}} bg={'rgba(217, 227, 243, 0.45)'} 
                        shadow={'xl'} 
                        borderRadius={'lg'}
                        position={'relative'}
                    >
                        {sessionContext.doesSessionExist && <IconButton
                                                                    aria-label='Menu'
                                                                    size={'md'} 
                                                                    icon={<MenuIcon color={'white'} />} 
                                                                    // w={6} 
                                                                    // h={6} 
                                                                    position={'absolute'} 
                                                                    top={{base: 3, sm: 3, md: 3, lg: 3, xl: 5, "2xl": 5}} 
                                                                    right={{base: 3, sm: 3, md: 3, lg: 3, xl: 5, "2xl": 5}} 
                                                                    onClick={() => onSettingsOpen()}
                                                                    color={'white'}
                                                                    bg={'none'}
                                                                    // color={'whiteAlpha.800'} 
                                                                    />}

                        {sessionContext.doesSessionExist && <IconButton
                                                                    isLoading={refreshLoading}
                                                                    aria-label='Refresh'
                                                                    size={'md'} 
                                                                    icon={<RefreshIcon color={'white'} />} 
                                                                    // w={6} 
                                                                    // h={6} 
                                                                    position={'absolute'} 
                                                                    top={{base: 3, sm: 3, md: 3, lg: 3, xl: 5, "2xl": 5}} 
                                                                    left={{base: 3, sm: 3, md: 3, lg: 3, xl: 5, "2xl": 5}} 
                                                                    onClick={() => handleRefreshClick()}
                                                                    color={'white'}
                                                                    bg={'none'}
                                                                    // color={'whiteAlpha.800'} 
                                                                    />}
                        <VStack>
                            <Heading isTruncated as="h2" size={{base: 'lg', sm: 'lg', md: "xl"}} p={0} color={'blackAlpha.700'} pt={10}>
                            <Box as="span" color={highlightColor}>{reminderBoxHeader}</Box> 
                            {/* Tell me what to remember: */}
                            </Heading>
                            <Box w={'80%'} pt={4}>
                                <Formik 
                                initialValues={{reminderInput: ''}}
                                // enableReinitialize={true}
                                validateOnChange={false}
                                validateOnBlur={false}
                                onSubmit={async (values, actions) => {
                                    // console.log('values: ' + values.reminderInput)
                                    if (mode === 'demo') {
                                        console.log("Demo mode onSubmit")
                                        let obj = {}
                                        obj.input_text = values.reminderInput
                                        obj.user_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                                        try {
                                            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/reminder-demo", {
                                                method: "POST",
                                                headers: {
                                                    "Content-Type": "application/json"
                                                },
                                                body: JSON.stringify(obj)
                                            })
                                            let resJson = await res.json();
                                            // TODO: use resJson to add a dummy reminder to dummyreminderlist
                                            // console.log(resJson);
                                            setReminderToAdd(resJson)
                                            actions.resetForm({
                                                values: {
                                                    reminderInput: ''
                                                }
                                            })

                                        } catch (err) {
                                            console.log(err);
                                            console.log("some error occurred in demo reminder call to API");
                                        }



                                    } else {
                    
                    
                                        setLoading(true)
                                        // alert(JSON.stringify(values, null, 2));
                                        let obj = {}
                                        // const user_timzeone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                                        obj.input_text = values.reminderInput
                                        obj.user_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                                        obj.supertokens_id = userId
                                        // console.log(obj)
                    
                                        try {
                                            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/reminder", {
                                                method: "POST",
                                                headers: {
                                                    "Content-Type": "application/json"
                                                },
                                                body: JSON.stringify(obj)
                                            })
                                            let resJson = await res.json();
                                            if (res.status === 200) {
                                                // console.log(resJson);
                                                setLoading(false)
                                                setNewReminder(resJson)
                                                // setNewReminderPopNeeded(true)
                                                // if user is logged in, then set the new reminder
                    
                                                if (doesSessionExist) {
                                                    // setNewReminder(resJson)
                                                    if (user_details.channels.length > 0) {
                                                        // setNeedsRefresh(true)
                                                        setNewReminderPopNeeded(true)
                                                        user.setNeedsRefresh(true)
                                                    } else {
                                                        // If user is logged in but does not have a channel, do something
                                                        setAddChannelPopNeeded(true)
                                                        user.setNeedsRefresh(true)
                                                    }
                                                    setNeedsRefresh(true)
                                                } else {
                                                    setLogInPromptNeeded(true)
                                                    user.setNeedsRefresh(true)
                                                }
                    
                                            } else {
                                                // logtail.error("Error in adding reminder", {
                                                //     status: res.status,
                                                //     message: resJson.message,
                                                //     user: userId,
                                                //     reminder: obj
                                                // })
                                                toast({
                                                    title: "Error",
                                                    description: "Something went wrong in 'else'. Please try again.",
                                                    status: "error",
                                                    duration: 4000,
                                                    isClosable: true,
                                                })
                                                setLoading(false)
                                            }
                                            } catch (err) {
                                                // logtail.error("Error in adding reminder", {
                                                //     message: err.message,
                                                //     user: userId,
                                                //     reminder: obj
                                                // })
                                                console.log(err);
                                                // console.log("some error occurred");
                                                toast({
                                                    title: "Error",
                                                    description: "Something went wrong in 'catch'. Please try again.",
                                                    status: "error",
                                                    duration: 4000,
                                                    isClosable: true,
                                                })
                                                setLoading(false)
                                            }
                                            actions.resetForm({
                                                values: {
                                                    reminderInput: ''
                                                }
                                            })
                                            // setNeedsRefresh(true)
                                    }
                                    
                                }}
                                validate={(value) => {
                                    let errors = {};
                                    // console.log('value: ' + value.reminderInput)
                                    // let error;
                                    if (user_details.account_type === 'free') {
                                        console.log('user has set ' + countRemindersInLast30Days(user_details) + ' reminders in the last 30 days')
                                        if (countRemindersInLast30Days(user_details) >= 5) {
                                            errors.reminderInput = "You have reached your reminder limit for the month.  Please upgrade to a paid plan to continue setting reminders."
                                        }
                                    }
                                    if (value.reminderInput.length < 1) {
                                        errors.reminderInput = "Please enter a reminder";
                                    } else if (value.reminderInput.length > 90) {
                                        errors.reminderInput = "Please enter a shorter reminder";
                                    }
                                    return errors
                                }}

                            >
                                <ReminderInputBox 
                                    // value={reminderInput}
                                    // onValueChange={(value) => setReminderInput(value)}
                                    mode={mode}
                                />
                            </Formik>
                            </Box>
                            <Box w={'80%'}>
                                
                                <Spacer h={7} />
                                <Flex direction="column" h="100%">
                                    
                                    <Box flex="1" overflowY="auto" position={'relative'}>
                                        {sessionContext.doesSessionExist ? <LiveReminderList /> : <DummyReminders reminderToAdd={reminderToAdd} openSignInUpModal={openModal}/>}
                                    </Box>
                                    
                                </Flex>
                                <Modal isOpen={isModalOpen} onClose={closeModal} size={'xl'}>
                                    <ModalOverlay bg={"blackAlpha.700"} backdropFilter='blur(5px)'/>
                                    <ModalContent>
                                        <ModalHeader>
                                            <ModalCloseButton />
                                        </ModalHeader>
                                        <ModalBody>
                                            <SignInAndUp />
                                        </ModalBody>
                                    </ModalContent>
                                </Modal>
                                <Modal isOpen={isSignUpModalOpen} onClose={closeSignUpModal} size={"2xl"}>
                                    <ModalOverlay bg={"blackAlpha.700"} backdropFilter='blur(5px)'/>
                                    <ModalContent h={{lg: "730px", xl: "730px", "2xl": "800px"}} bg={"brand.green"}>
                                        <ModalHeader>
                                            <ModalCloseButton />
                                        </ModalHeader>
                                        <ModalBody >
                                            <DecorativePricingTable size={"mobile"} closeChosePlanModal = {closeSignUpModal}/>
                                        </ModalBody>
                                    </ModalContent>
                                </Modal>

                                <Spacer h={10} />
                                
                            </Box>

                            
                        </VStack>
                    </Box>
                </Flex>
            </VStack>
            <VStack 
                spacing={0} 
                align={'center'} 
                bgGradient={'linear(to-t, brand.violet -50%, white 50%)'}
            >
                <Box 
                    p={0} 
                    w={maxColWidth}
                    display={'flex'}
                    // justifyContent={'flex-end'}
                    justifyContent={'center'}
                    alignContent={'center'}
                >
                    {/* Stack the rainbow semicircles behind the image */}
                    {/* {['rainbow_red', 'rainbow_orange', 'rainbow_yellow', 'rainbow_green', 'rainbow_blue', 'rainbow_indigo', 'rainbow_violet'].map((color, index) => (
                        <Box
                        key={color}
                        position="absolute"
                        left={`calc(-50% + ${index * 10}px)`} // Adjust left position based on the index
                        top="50%"
                        transform="translatey(-50%) translateX(30%)"
                        width={`calc(200% - ${index * 20}px)`} // Adjust width based on the index
                        height={`calc(100% - ${index * 20}px)`} // Adjust height based on the index
                        borderRadius="50%"
                        background={`var(--chakra-colors-brand-${color})`}
                        zIndex={-1} // Behind the image
                        />
                    ))} */}
                    {/* <Image h={{base: "md", md: "lg", lg: "xl"}} pt={3} pb={0} src='./Screenshot.png' alt='Screenshot of ReminderLoop app' /> */}
                    <Image h={{base: "md", md: "lg", lg: "xl"}} pt={3} pb={0} src='./Simulator Screenshot - iPhone 15 Pro Max - 2024-08-10 at 09.49.51-left.png' alt='Screenshot of ReminderLoop app' />
                </Box>
                <Box pb={5} pl={5} w={"95%"}>
                    <Heading as='h1' fontSize={{base: "xl", sm: "xl", md: "2xl"}} fontWeight={'bold'} color={'brand.violet_text'} pt={4}>
                        Why use ReminderLoop?
                    </Heading>
                    < Features />
                </Box>
            </VStack>
            <VStack 
                spacing={0} 
                align={'center'} 
                bgGradient={'linear(to-tr, brand.violet , brand.green )'}
            >
                <Box
                    p={0} 
                    w={maxColWidth}
                    display={'flex'}
                    justifyContent={'center'}
                >
                    {/* <ComparisonTable /> */}
                    <DecorativePricingTable size={"mobile"} openSignInUpModal={openModal}/>
                </Box>
                <Box pl={5} w={"95%"} display={"flex"} justifyContent={"center"}>
                    <Testimonials />
                </Box>
                <Flex pt={10} direction="column" h="100%" w={maxColWidth}>
                    <Image 
                        id='notify' 
                        w={{base: 'xs', sm: 'sm', md: 'md'}}
                        
                        alignSelf="center" // Align self can be used if you need specific alignment control
                        mt="auto" // This pushes the image to the bottom
                        // src='./notification-screenshot-cropped.png' 
                        src='/Simulator Screenshot - iPhone 15 Pro Max - 2024-08-10 at 09.22.02-portrait-notification.png'
                        alt='Screenshot of a notification in the ReminderLoop app'
                    />
                </Flex>
            </VStack>
            <Drawer isOpen={isSettingsOpen} placement="right" onClose={onSettingsClose} size={'xs'}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader></DrawerHeader>
                    <DrawerBody>
                        <SettingsDrawer openSignUpModal={openSignUpModal} onSettingsClose={onSettingsClose} />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    );
    
};

function countRemindersInLast30Days(user_data) {
    const now = new Date();
    const thirtyDaysAgo = new Date(now.getTime() - (30 * 24 * 60 * 60 * 1000));
    let count = 0;

    user_data.self_reminders.forEach(reminder => {
        const reminderDate = new Date(reminder.date_added);
        if (reminderDate > thirtyDaysAgo) {
            count++;
        }
    });

    return count;
}

export default HomeMobile;