import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {useSessionContext} from "supertokens-auth-react/recipe/session";
import {SuperTokensWrapper} from "supertokens-auth-react";
import { useNavigate} from "react-router-dom";
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-7WWR6KVC3P";


const root = ReactDOM.createRoot(document.getElementById('root'));

ReactGA.initialize(TRACKING_ID);

root.render(
  <React.StrictMode>
      <SuperTokensWrapper>
      {/*    <BrowserRouter>*/}
      {/*        <Routes>*/}
      {/*            /!*This renders the login UI on the /auth route*!/*/}
      {/*            {getSuperTokensRoutesForReactRouterDom(reactRouterDom)}*/}
      {/*            /!*Your app routes*!/*/}
      {/*            <NavigateSetter />*/}
                  <App />
      {/*        </Routes>*/}
      {/*    </BrowserRouter>*/}
      </SuperTokensWrapper>


  </React.StrictMode>
);

const SendAnalytics = ()=> {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);


